@font-face {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/OpenSans-Semibold.tff') format('tff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/OpenSans-Regular.tff') format('tff');
}