@use "sass:math";
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

@layer utilities {
    /*Read More On Hover*/

    #readMore {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 2em;
        z-index: 100;
    }

    .fulltext-tooltip {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-out;
        position: absolute;
        top: 0;
        left: 0;
        @apply bg-rc-dark pb-1 font-bold text-white;
    }

    #readMore:hover + .fulltext-tooltip {
        visibility: visible;
        transition: opacity 0.2s ease-in;
        opacity: 1;
    }

    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -20px; /* gutter size offset */
        width: auto;
    }
    .my-masonry-grid_column {
        padding-left: 20px; /* gutter size */
        background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > .masonry_item {
        /* change div to reference your elements you put in <Masonry> */
        display: flex;
        margin-bottom: 20px;
    }

    .my-masonry-grid_column > a {
        /* change div to reference your elements you put in <Masonry> */
        display: flex;
        margin-bottom: 20px;
    }
    .my-masonry-grid_column > img {
        display: flex;
        margin-bottom: 20px;
    }

    .break-inside {
        break-inside: avoid;
    }

    .text-shadow {
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
    }
    //
    .text-shadow-none {
        text-shadow: none;
    }

    .gradient-header {
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5) 100%);
        mix-blend-mode: multiply;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        @media screen and (max-width: 500px) {
            // Hide on mmbile
            display: none;
        }

        @media screen and (min-width: 970px) {
            // hide on screens bigger than tablet
            display: none;
        }
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        @media screen and (max-width: 500px) {
            // Hide on mmbile
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
        @media screen and (min-width: 970px) {
            // hide on screens bigger than tablet
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }

    /*Headlines*/
    .banner-headline {
        &-big {
            @apply text-shadow font-oswald text-3xl font-bold uppercase md:text-6xl 2xl:text-7xl;
        }
        &-subtitle {
            @apply text-shadow font-oswald uppercase md:text-2xl;
        }
    }
    .headline-gradient {
        @apply cursor-default bg-gradient-to-r from-rc-pink to-rc-blue bg-clip-text text-center font-oswald text-3xl font-bold uppercase text-transparent md:text-left md:text-4xl;
    }

    .react-loading-skeleton::after {
        background-image: linear-gradient(90deg, #9e7ec629, #7587d236, #588edb2b) !important;
    }

    .text-gradient {
        @apply cursor-default bg-gradient-to-r from-rc-pink to-rc-blue bg-clip-text text-transparent;
    }

    .font-headline {
        @apply font-oswald uppercase;
    }

    .post img {
        @apply pb-1 pt-4;
    }

    /*Buttons*/
    .gradient-button {
        @apply rounded-md bg-gradient-to-r from-rc-pink to-rc-blue px-5 py-2 text-base tracking-wider text-rc-darker transition hover:opacity-90;
    }

    .gradient-button-animate {
        transition: 0.5s;
        background-size: 200% auto;
        background-image: linear-gradient(to right, #ec6ead 0%, #3494e6 51%, #ec6ead 100%);
        @apply transform rounded-md px-4 py-3 text-base tracking-wider text-white hover:scale-105;
    }

    .gradient-button-animate:hover {
        background-position: right center; /* change the direction of the change here */
    }

    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }

    /* Tooltip arrow */
    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    //FANCY STUFF
    //GLITCH 404
    .glitchwrap {
        background: rgb(31, 31, 31);
        box-shadow: 0px 0px 122px 25px black inset;
        background-image: url("/noise.jpg");
        background-repeat: repeat;
        animation: noise 230ms steps(6) infinite;
    }

    @keyframes noise {
        0% {
            background-position-y: 0px;
        }
        100% {
            background-position-y: -420px;
        }
    }

    .glitch {
        font-size: 12rem;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff, 0.025em 0.04em 0 #fffc00;
        animation: glitch 725ms infinite;
    }

    .glitch span {
        position: absolute;
        top: 0;
        left: 0;
    }

    .glitch span:first-child {
        animation: glitch 500ms infinite;
        clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
        transform: translate(-0.04em, -0.03em);
        opacity: 0.75;
    }

    .glitch span:last-child {
        animation: glitch 375ms infinite;
        clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
        transform: translate(0.04em, 0.03em);
        opacity: 0.75;
    }

    @keyframes glitch {
        0% {
            text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff, 0.025em 0.04em 0 #fffc00;
        }
        15% {
            text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff, 0.025em 0.04em 0 #fffc00;
        }
        16% {
            text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff, -0.05em -0.05em 0 #fffc00;
        }
        49% {
            text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff, -0.05em -0.05em 0 #fffc00;
        }
        50% {
            text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff, 0 -0.04em 0 #fffc00;
        }
        99% {
            text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff, 0 -0.04em 0 #fffc00;
        }
        100% {
            text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff, -0.04em -0.025em 0 #fffc00;
        }
    }
}

:root {
    --default-line-height: 1.65;
}
body {
    line-height: var(--default-line-height);
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
    @apply -translate-y-6 scale-75 transform duration-300;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #181818 inset !important;
    box-shadow: 0 0 0 1000px #181818 inset !important;
    -webkit-text-fill-color: #fff !important;
}

input:checked + div {
    @apply border-rc-pink dark:border-rc-pink;
}
input:checked + div svg {
    @apply block;
}

/* width */
::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 100vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8c8c8c;
}

.pink-scrollbar::-webkit-scrollbar {
    width: 1em;
    height: 1em;
    border: #c29090;
    border-width: 0.5em;
}

/* Track */
.pink-scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
}

/* Handle */
.pink-scrollbar::-webkit-scrollbar-thumb {
    background: #ec6ead;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

/* Toggle B */
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #3494e6;
}

.slick-dots {
    li button:before,
    li.slick-active button:before {
        color: transparent;
        opacity: 1;
    }
    li button:before {
        background-color: #181818;
        border-radius: 50%;
        display: inline-block;
        height: 20px !important;
        width: 20px !important;
        color: #181818 !important;
    }
    li.slick-active button:before {
        background-color: #181818;
        color: #181818 !important;
    }
}
audio {
    width: 100%;
}

.truncate-2line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-content {
    @media screen and (max-width: 768px) {
        height: 100vh;
    }
    @media screen and (min-width: 768px) {
        max-height: calc(100vh - 100px);
    }

    overflow-y: auto;
    overflow-x: hidden;
}

.leaflet-container {
    z-index: 10;
}

.card-shadow {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.animate-hover {
    animation: hoverAnim 2s ease 0s infinite normal both;

    @keyframes hoverAnim {
        0%,
        100% {
            transform: translateY(-5%);
            animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
        }

        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

// Radial Progress Bar
// -- vars
$color: #e86fae;
$bg-color: #181818;
$default-size: 1em;
$label-font-size: $default-size / 3;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin radialSize($width, $height) {
    height: $height;
    width: $width;
}

@mixin draw-progress($progress, $color) {
    .pie {
        .half-circle {
            border-color: $color;
        }

        .left-side {
            transform: rotate($progress * 3.6deg);
        }

        @if $progress <= 50 {
            .right-side {
                display: none;
            }
        } @else {
            clip: rect(auto, auto, auto, auto);

            .right-side {
                transform: rotate(180deg);
            }
        }
    }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
    background: linear-gradient(to right, $color 50%, $bg-color 50%);

    &:before {
        @if $progress <= 50 {
            background: $bg-color;
            transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
        } @else {
            background: $color;
            transform: rotate((100 - $progress) / 100 * 360deg);
        }
    }
}

.set-size {
    display: inline-block;
    font-size: 36px;
}

.charts-container {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}

.pie-wrapper {
    @include radialSize($default-size, $default-size);
    float: left;
    position: relative;
    @apply rounded-full bg-rc-dark;

    &:nth-child(3n + 1) {
        clear: both;
    }

    .pie {
        @include radialSize(100%, 100%);
        clip: rect(0, $default-size, $default-size, $default-size / 2);
        left: 0;
        position: absolute;
        top: 0;

        .half-circle {
            @include radialSize(100%, 100%);
            border: ($default-size / 10) solid #3498db;
            border-radius: 50%;
            clip: rect(0, $default-size / 2, $default-size, 0);
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .label {
        background: $bg-color;
        border-radius: 50%;
        bottom: $label-font-size-redo / 10;
        color: #ecf0f1;
        cursor: default;
        font-size: $label-font-size;
        left: $label-font-size-redo / 10;
        line-height: $label-font-size-redo * 0.7;
        position: absolute;
        right: $label-font-size-redo / 10;
        text-align: center;
        top: $label-font-size-redo / 10;
    }
    .number {
        position: absolute;
        top: -4px;
        left: 7px;
    }
    @for $i from 0 through 100 {
        &.progress-#{$i} {
            @include draw-progress($i, $color);
        }
    }
}

.sign-up-modal {
    input,
    label,
    select {
        color: black !important;
    }

    span:not(.text-rc-pink) {
        color: black !important;
    }

    option {
        color: black !important;
        background-color: white !important;
    }

    .interests-grid span {
        color: white !important;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(0);
    }
}
