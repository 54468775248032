.wiki-blog .mw-cite-backlink,
.wiki-blog .cite-accessibility-label {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wiki-blog .mw-references-columns {
  -webkit-column-width: 30em;
  -moz-column-width: 30em;
  column-width: 30em;
  margin-top: 0.3em;
}
.wiki-blog .mw-parser-output .mw-references-columns .references {
  margin-top: 0;
}
.wiki-blog .mw-references-columns li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
.wiki-blog ol.references {
  counter-reset: mw-ref-extends-parent list-item;
}
.wiki-blog ol.references > li {
  display: list-item;
  list-style: auto;
  counter-increment: mw-ref-extends-parent;
  counter-reset: mw-ref-extends-child;
}
.wiki-blog ol.references .mw-extended-references {
  list-style: none;
  margin-left: 2em;
}
.wiki-blog .mw-extended-references > li:before {
  counter-increment: mw-ref-extends-child;
  content: counter(mw-ref-extends-parent, decimal) '.' counter(mw-ref-extends-child, decimal) '. ';
}
.wiki-blog sup.reference {
  unicode-bidi: -moz-isolate;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  white-space: nowrap;
  font-weight: normal;
  font-style: normal;
}
.wiki-blog ol.references li:target,
.wiki-blog sup.reference:target {
  background-color: #eaf3ff;
}
.wiki-blog .mw-ext-cite-error {
  font-weight: bold;
  unicode-bidi: embed;
}
.wiki-blog .mw-ext-cite-error code {
  background-color: transparent;
  color: inherit;
  padding: 1px 2px;
}
.wiki-blog .mw-cite-dir-ltr .reference-text {
  direction: ltr;
  unicode-bidi: embed;
}
.wiki-blog .mw-cite-dir-rtl .reference-text {
  direction: rtl;
  unicode-bidi: embed;
}
@media print {
  .wiki-blog .mw-cite-backlink {
    display: none;
  }
}
.wiki-blog div.flaggedrevs_basic,
.wiki-blog div.flaggedrevs_notice {
  border: 1px solid #a2a9b1;
  padding: 5px;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0.5em 1em 0 0;
  text-align: center;
  clear: both;
}
.wiki-blog div.flaggedrevs_short_basic img {
  vertical-align: text-bottom;
}
.wiki-blog table.flaggedrevs_editnotice,
.wiki-blog table.flaggedrevs_viewnotice {
  padding: 3px;
  border: 2px solid #a2a9b1;
  text-align: left;
  width: 100%;
  clear: both;
}
.wiki-blog div.flaggedrevs_diffnotice,
.wiki-blog div.flaggedrevs_preview {
  border: 1px solid #c8ccd1;
  padding: 5px;
  text-align: center;
  clear: both;
}
.wiki-blog div.flaggedrevs_diffnotice {
  font-size: 85%;
}
.wiki-blog span.flaggedrevs_important {
  font-size: 115%;
  font-weight: bold;
}
.wiki-blog div.flaggedrevs_notes {
  border: 1px solid #a2a9b1;
  padding: 5px;
  font-size: 85%;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}
.wiki-blog .fr-text-value {
  width: 100px;
}
.wiki-blog .fr-marker-20,
.wiki-blog .fr-marker-40,
.wiki-blog .fr-marker-60,
.wiki-blog .fr-marker-80,
.wiki-blog .fr-marker-100 {
  background-position: bottom left;
  background-repeat: no-repeat;
  padding-bottom: 3px;
}
.wiki-blog div.flaggedrevs_short {
  border: 1px solid #c8ccd1;
  font-size: 95%;
  font-weight: normal;
  margin: 0 0 0 1em;
  line-height: 16px;
  padding: 2px;
  float: right;
}
.wiki-blog .sitedir-ltr div.flaggedrevs_short {
  float: right;
  margin: 0 0 0 1em;
}
.wiki-blog .sitedir-rtl div.flaggedrevs_short {
  float: left;
  margin: 0 1em 0 0;
}
.wiki-blog div.flaggedrevs_short_basic {
  white-space: nowrap;
}
.wiki-blog div.flaggedrevs_short_details {
  border: 1px solid #a2a9b1;
  padding: 5px;
  position: absolute;
  top: 0;
  right: -2px;
  width: 25em;
}
.wiki-blog .sitedir-ltr div.flaggedrevs_short_details {
  right: -2px;
  left: auto;
}
.wiki-blog .sitedir-rtl div.flaggedrevs_short_details {
  left: -2px;
  right: auto;
}
.wiki-blog .fr-text {
  height: 1em;
  line-height: 1em;
  margin: 0 7px 0 0;
  padding: 0;
  font-weight: bold;
  width: 80px;
}
.wiki-blog .fr-value20,
.wiki-blog .fr-value40,
.wiki-blog .fr-value60,
.wiki-blog .fr-value80,
.wiki-blog .fr-value100 {
  height: 1em;
  line-height: 1em;
  width: 95px;
  float: left;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;
}

.wiki-blog a.fr-toggle-symbol {
  white-space: nowrap;
  font-family: monospace;
  font-weight: bold;
  cursor: pointer;
  margin: 0 0.3em;
}
.wiki-blog a.fr-toggle-text {
  font-weight: bold;
  cursor: pointer;
}
.wiki-blog span.fr-toggle-arrow {
  cursor: pointer;
  opacity: 0.87;
}
.wiki-blog li.fr-hist-stable-margin {
  margin-top: 2em;
}
.wiki-blog span.flaggedrevs-icon {
  margin-right: 0.2em;
  margin-left: 0.2em;
  opacity: 0.87;
}
.wiki-blog .fr-diff-ratings {
  font-size: 90%;
  font-weight: bold;
  line-height: 1em;
  width: 100%;
}
.wiki-blog .fr-diff-to-stable {
  line-height: 1em;
}
.wiki-blog .fr-watchlist-pending-notice {
  padding: 3px;
  margin-bottom: 5px;
  border: 1px solid #b32424;
}

.wiki-blog .mw-fr-reviewlink {
  font-weight: bold;
}
.wiki-blog .fr-hist-quality-auto,
.wiki-blog .fr-hist-basic-auto,
.wiki-blog .fr-hist-quality-user,
.wiki-blog .fr-hist-basic-user,
.wiki-blog .mw-fr-hist-difflink {
  font-size: 85%;
}
.wiki-blog #mw-fr-diff-headeritems {
  direction: ltr;
}
.wiki-blog #mw-fr-stablediff {
  text-align: left;
}
.wiki-blog .oo-ui-icon-eye,
.wiki-blog .mw-ui-icon-eye:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=ext.flaggedRevs.icons&image=eye&format=rasterized&skin=vector&version=1m2cj);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eeye%3C/title%3E%3Cpath d=%22M10 14.5a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5zM10 3C3 3 0 10 0 10s3 7 10 7 10-7 10-7-3-7-10-7z%22/%3E%3Ccircle cx=%2210%22 cy=%2210%22 r=%222.5%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-articleCheck,
.wiki-blog .mw-ui-icon-articleCheck:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=ext.flaggedRevs.icons&image=articleCheck&format=rasterized&skin=vector&version=1m2cj);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Earticle check%3C/title%3E%3Cpath d=%22M9 17l-4.59-4.59L5.83 11 9 14.17l8-8V3a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h10a2 2 0 002-2V9z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-articleSearch,
.wiki-blog .mw-ui-icon-articleSearch:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=ext.flaggedRevs.icons&image=articleSearch&format=rasterized&skin=vector&version=1m2cj);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Earticle search%3C/title%3E%3Cpath d=%22M12.43 14.34A5 5 0 0110 15a5 5 0 113.95-2L17 16.09V3a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h10a2 2 0 001.45-.63z%22/%3E%3Ccircle cx=%2210%22 cy=%2210%22 r=%223%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=ext.flaggedRevs.icons&image=check&format=rasterized&skin=vector&version=1m2cj);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-block,
.wiki-blog .mw-ui-icon-block:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=ext.flaggedRevs.icons&image=block&format=rasterized&skin=vector&version=1m2cj);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eblock%3C/title%3E%3Cpath d=%22M10 1a9 9 0 109 9 9 9 0 00-9-9zm5 10H5V9h10z%22/%3E%3C/svg%3E');
}
.wiki-blog .timeline-wrapper {
  max-width: 100%;
  overflow: auto;
}
.wiki-blog #p-lang .uls-settings-trigger {
  background: transparent url(https://wikipedia.org/w/extensions/UniversalLanguageSelector/resources/images/cog-sprite.svg?c3fa1)
    no-repeat center top;
  border: 0;
  min-height: 16px;
  min-width: 16px;
  float: right;
  cursor: pointer;
}
.wiki-blog #p-lang .uls-settings-trigger::-moz-focus-inner {
  border: 0;
}
.wiki-blog #p-lang .uls-settings-trigger:focus {
  outline: 1px solid #ee8ebe;
}
.wiki-blog .skin-vector #p-lang .uls-settings-trigger {
  margin-top: 8px;
}
.wiki-blog #p-lang .uls-settings-trigger:hover {
  background-position: center -16px;
}
.wiki-blog .client-nojs #ca-ve-edit,
.wiki-blog .client-nojs .mw-editsection-divider,
.wiki-blog .client-nojs .mw-editsection-visualeditor,
.wiki-blog .ve-not-available #ca-ve-edit,
.wiki-blog .ve-not-available .mw-editsection-divider,
.wiki-blog .ve-not-available .mw-editsection-visualeditor {
  display: none;
}
.wiki-blog .client-js .mw-content-ltr .mw-editsection-bracket:first-of-type,
.wiki-blog .client-js .mw-content-rtl .mw-editsection-bracket:not(:first-of-type) {
  margin-right: 0.25em;
  color: #54595d;
}
.wiki-blog .client-js .mw-content-rtl .mw-editsection-bracket:first-of-type,
.wiki-blog .client-js .mw-content-ltr .mw-editsection-bracket:not(:first-of-type) {
  margin-left: 0.25em;
  color: #54595d;
}
.wiki-blog .badge-goodarticle,
.wiki-blog .badge-goodlist,
.wiki-blog .badge-recommendedarticle {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-silver-star.png?70a8c);
}
.wiki-blog .badge-featuredarticle,
.wiki-blog .badge-featuredportal,
.wiki-blog .badge-featuredlist {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-golden-star.png?ed948);
}
.wiki-blog .badge-problematic {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-problematic.png?f3177);
}
.wiki-blog .badge-proofread {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-proofread.png?e81f9);
}
.wiki-blog .badge-validated {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-validated.png?6232c);
}
.wiki-blog .badge-digitaldocument {
  list-style-image: url(https://wikipedia.org/w/extensions/WikimediaBadges/resources/images/badge-digitaldocument.png?d1c50);
}
.wiki-blog .mw-widget-complexNamespaceInputWidget .mw-widget-namespaceInputWidget,
.wiki-blog .mw-widget-complexNamespaceInputWidget .oo-ui-fieldLayout {
  display: inline-block;
  margin-right: 1em;
}
.wiki-blog .mw-widget-complexNamespaceInputWidget .oo-ui-fieldLayout {
  vertical-align: middle;
  margin-bottom: 0;
}
.wiki-blog
  .mw-widget-complexNamespaceInputWidget
  .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline.oo-ui-labelElement
  > .oo-ui-fieldLayout-body
  > .oo-ui-labelElement-label {
  padding-left: 0.5em;
}
.wiki-blog .mw-widget-complexNamespaceInputWidget .mw-widget-namespaceInputWidget {
  max-width: 20em;
}
.wiki-blog .mw-widget-complexTitleInputWidget .mw-widget-namespaceInputWidget,
.wiki-blog .mw-widget-complexTitleInputWidget .mw-widget-titleInputWidget {
  display: inline-block;
}
.wiki-blog .mw-widget-complexTitleInputWidget .mw-widget-namespaceInputWidget {
  max-width: 20em;
  margin-right: 0.5em;
}
.wiki-blog .mw-widget-complexTitleInputWidget .mw-widget-titleInputWidget {
  max-width: 29.5em;
}
.wiki-blog .oo-ui-icon-alert,
.wiki-blog .mw-ui-icon-alert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=alert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Ealert%3C/title%3E%3Cpath d=%22M11.53 2.3A1.85 1.85 0 0010 1.21 1.85 1.85 0 008.48 2.3L.36 16.ee8ebe-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-alert,
.wiki-blog .mw-ui-icon-alert-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=alert&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Ealert%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M11.53 2.3A1.85 1.85 0 0010 1.21 1.85 1.85 0 008.48 2.3L.36 16.ee8ebe-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-alert,
.wiki-blog .mw-ui-icon-alert-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=alert&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Ealert%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M11.53 2.3A1.85 1.85 0 0010 1.21 1.85 1.85 0 008.48 2.3L.36 16.ee8ebe-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-warning.oo-ui-icon-alert,
.wiki-blog .mw-ui-icon-alert-warning:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=alert&variant=warning&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Ealert%3C/title%3E%3Cg fill=%22%23fc3%22%3E%3Cpath d=%22M11.53 2.3A1.85 1.85 0 0010 1.21 1.85 1.85 0 008.48 2.3L.36 16.ee8ebe-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-error,
.wiki-blog .mw-ui-icon-error:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=error&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eerror%3C/title%3E%3Cpath d=%22M13.728 1H6.272L1 6.272v7.456L6.272 19h7.456L19 13.728V6.272zM11 15H9v-2h2zm0-4H9V5h2z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-error,
.wiki-blog .mw-ui-icon-error-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=error&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eerror%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M13.728 1H6.272L1 6.272v7.456L6.272 19h7.456L19 13.728V6.272zM11 15H9v-2h2zm0-4H9V5h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-error,
.wiki-blog .mw-ui-icon-error-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=error&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eerror%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M13.728 1H6.272L1 6.272v7.456L6.272 19h7.456L19 13.728V6.272zM11 15H9v-2h2zm0-4H9V5h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-error.oo-ui-icon-error,
.wiki-blog .mw-ui-icon-error-error:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=error&variant=error&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eerror%3C/title%3E%3Cg fill=%22%23d33%22%3E%3Cpath d=%22M13.728 1H6.272L1 6.272v7.456L6.272 19h7.456L19 13.728V6.272zM11 15H9v-2h2zm0-4H9V5h2z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-info,
.wiki-blog .mw-ui-icon-info:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=info&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cpath d=%22M9.5 16A6.61 6.61 0 013 9.5 6.61 6.61 0 019.5 3 6.61 6.61 0 0116 9.5 6.63 6.63 0 019.5 16zm0-14A7.5 7.5 0 1017 9.5 7.5 7.5 0 009.5 2zm.5 6v4.08h1V13H8.07v-.92H9V9H8V8zM9 6h1v1H9z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-info,
.wiki-blog .mw-ui-icon-info-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=info&variant=invert&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M9.5 16A6.61 6.61 0 013 9.5 6.61 6.61 0 019.5 3 6.61 6.61 0 0116 9.5 6.63 6.63 0 019.5 16zm0-14A7.5 7.5 0 1017 9.5 7.5 7.5 0 009.5 2zm.5 6v4.08h1V13H8.07v-.92H9V9H8V8zM9 6h1v1H9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-info,
.wiki-blog .mw-ui-icon-info-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=info&variant=progressive&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M9.5 16A6.61 6.61 0 013 9.5 6.61 6.61 0 019.5 3 6.61 6.61 0 0116 9.5 6.63 6.63 0 019.5 16zm0-14A7.5 7.5 0 1017 9.5 7.5 7.5 0 009.5 2zm.5 6v4.08h1V13H8.07v-.92H9V9H8V8zM9 6h1v1H9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-infoFilled,
.wiki-blog .mw-ui-icon-infoFilled:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=infoFilled&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cpath d=%22M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM9 5h2v2H9zm0 4h2v6H9z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-infoFilled,
.wiki-blog .mw-ui-icon-infoFilled-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=infoFilled&variant=invert&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM9 5h2v2H9zm0 4h2v6H9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-infoFilled,
.wiki-blog .mw-ui-icon-infoFilled-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=infoFilled&variant=progressive&format=rasterized&lang=de&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Einfo%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM9 5h2v2H9zm0 4h2v6H9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-add,
.wiki-blog .mw-ui-icon-add:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=add&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eadd%3C/title%3E%3Cpath d=%22M11 9V4H9v5H4v2h5v5h2v-5h5V9z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-add,
.wiki-blog .mw-ui-icon-add-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=add&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eadd%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M11 9V4H9v5H4v2h5v5h2v-5h5V9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-add,
.wiki-blog .mw-ui-icon-add-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=add&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eadd%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M11 9V4H9v5H4v2h5v5h2v-5h5V9z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=check&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=check&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=check&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-destructive.oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check-destructive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=check&variant=destructive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cg fill=%22%23d33%22%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-success.oo-ui-icon-check,
.wiki-blog .mw-ui-icon-check-success:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=check&variant=success&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cg fill=%22%2314866d%22%3E%3Cpath d=%22M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-close,
.wiki-blog .mw-ui-icon-close:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=close&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclose%3C/title%3E%3Cpath d=%22M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z%22/%3E%3Cpath d=%22M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-close,
.wiki-blog .mw-ui-icon-close-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=close&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclose%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z%22/%3E%3Cpath d=%22M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-close,
.wiki-blog .mw-ui-icon-close-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=close&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclose%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z%22/%3E%3Cpath d=%22M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-destructive.oo-ui-icon-close,
.wiki-blog .mw-ui-icon-close-destructive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=close&variant=destructive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclose%3C/title%3E%3Cg fill=%22%23d33%22%3E%3Cpath d=%22M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z%22/%3E%3Cpath d=%22M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-search,
.wiki-blog .mw-ui-icon-search:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=search&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esearch%3C/title%3E%3Cpath d=%22M12.2 13.6a7 7 0 111.4-1.4l5.4 5.4-1.4 1.4-5.4-5.4zM3 8a5 5 0 1010 0A5 5 0 103 8z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-search,
.wiki-blog .mw-ui-icon-search-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=search&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esearch%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M12.2 13.6a7 7 0 111.4-1.4l5.4 5.4-1.4 1.4-5.4-5.4zM3 8a5 5 0 1010 0A5 5 0 103 8z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-search,
.wiki-blog .mw-ui-icon-search-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=search&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esearch%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M12.2 13.6a7 7 0 111.4-1.4l5.4 5.4-1.4 1.4-5.4-5.4zM3 8a5 5 0 1010 0A5 5 0 103 8z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-icon-subtract,
.wiki-blog .mw-ui-icon-subtract:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=subtract&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esubtract%3C/title%3E%3Cpath d=%22M4 9h12v2H4z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-icon-subtract,
.wiki-blog .mw-ui-icon-subtract-invert:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=subtract&variant=invert&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esubtract%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M4 9h12v2H4z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-progressive.oo-ui-icon-subtract,
.wiki-blog .mw-ui-icon-subtract-progressive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=subtract&variant=progressive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esubtract%3C/title%3E%3Cg fill=%22%23ee8ebe%22%3E%3Cpath d=%22M4 9h12v2H4z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-destructive.oo-ui-icon-subtract,
.wiki-blog .mw-ui-icon-subtract-destructive:before {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui-core.icons&image=subtract&variant=destructive&format=rasterized&skin=vector&version=1n6el);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Esubtract%3C/title%3E%3Cg fill=%22%23d33%22%3E%3Cpath d=%22M4 9h12v2H4z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-element-hidden {
  display: none !important;
}
.wiki-blog .oo-ui-buttonElement {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}
.wiki-blog .oo-ui-buttonElement > .oo-ui-buttonElement-button {
  cursor: pointer;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  font-family: inherit;
  font-size: inherit;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.wiki-blog .oo-ui-buttonElement > .oo-ui-buttonElement-button::-moz-focus-inner {
  border-color: transparent;
  padding: 0;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-widget-disabled > .oo-ui-buttonElement-button {
  cursor: default;
}
.wiki-blog .oo-ui-buttonElement-frameless {
  position: relative;
}
.wiki-blog .oo-ui-buttonElement-framed > .oo-ui-buttonElement-button {
  vertical-align: top;
  text-align: center;
}
.wiki-blog .oo-ui-buttonElement > .oo-ui-buttonElement-button {
  position: relative;
  min-height: 32px;
  border-radius: 2px;
  padding-top: 2.14285714em;
  font-weight: bold;
  text-decoration: none;
}
.wiki-blog .oo-ui-buttonElement > .oo-ui-buttonElement-button:focus {
  outline: 0;
}
.wiki-blog .oo-ui-buttonElement > input.oo-ui-buttonElement-button {
  -webkit-appearance: none;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-labelElement > .oo-ui-buttonElement-button {
  line-height: 1;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-labelElement > input.oo-ui-buttonElement-button,
.wiki-blog .oo-ui-buttonElement.oo-ui-labelElement > .oo-ui-buttonElement-button > .oo-ui-labelElement-label {
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-labelElement.oo-ui-indicatorElement > .oo-ui-buttonElement-button {
  padding-right: 2.14285714em;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-iconElement .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-buttonElement.oo-ui-indicatorElement .oo-ui-indicatorElement-indicator {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.wiki-blog
  .oo-ui-buttonElement.oo-ui-indicatorElement.oo-ui-labelElement
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator,
.wiki-blog
  .oo-ui-buttonElement.oo-ui-indicatorElement.oo-ui-iconElement
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  right: 0.71428571em;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-widget-enabled > .oo-ui-buttonElement-button {
  -webkit-transition: background-color 100ms, color 100ms, border-color 100ms, box-shadow 100ms;
  transition: background-color 100ms, color 100ms, border-color 100ms, box-shadow 100ms;
}
.wiki-blog .oo-ui-buttonElement.oo-ui-widget-enabled > .oo-ui-buttonElement-button > .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-buttonElement.oo-ui-widget-enabled > .oo-ui-buttonElement-button > .oo-ui-indicatorElement-indicator {
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
}
.wiki-blog
  .oo-ui-buttonElement.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon:not(.oo-ui-image-invert),
.wiki-blog
  .oo-ui-buttonElement.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator:not(.oo-ui-image-invert) {
  opacity: 0.87;
}
.wiki-blog
  .oo-ui-buttonElement.oo-ui-widget-enabled.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-buttonElement.oo-ui-widget-enabled.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-iconElement:first-child {
  margin-left: -0.42857143em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-iconElement > .oo-ui-buttonElement-button {
  min-width: 32px;
  min-height: 32px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  padding-top: 2.14285714em;
  padding-left: 2.14285714em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-iconElement > .oo-ui-buttonElement-button > .oo-ui-iconElement-icon {
  left: 0.35714286em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-labelElement:first-child {
  margin-left: -6px;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-labelElement.oo-ui-iconElement:first-child {
  margin-left: -0.42857143em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-labelElement > .oo-ui-buttonElement-button {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  padding: 5px 6px;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-labelElement.oo-ui-iconElement > .oo-ui-buttonElement-button {
  padding-left: 2.14285714em;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-indicatorElement:not(.oo-ui-iconElement):not(.oo-ui-labelElement)
  > .oo-ui-buttonElement-button {
  min-width: 12px;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-indicatorElement.oo-ui-iconElement > .oo-ui-buttonElement-button {
  padding-left: 3.85714286em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-indicatorElement.oo-ui-labelElement > .oo-ui-buttonElement-button {
  padding-left: 6px;
  padding-top: 5px;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-indicatorElement.oo-ui-iconElement.oo-ui-labelElement
  > .oo-ui-buttonElement-button {
  padding-left: 2.14285714em;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled > .oo-ui-buttonElement-button {
  color: #202122;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled > .oo-ui-buttonElement-button:hover {
  background-color: rgba(0, 24, 73, 0.027450980392157);
  color: #181818;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled > .oo-ui-buttonElement-button:focus {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe;
  outline: 1px solid transparent;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-buttonElement-pressed > input.oo-ui-buttonElement-button,
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled > .oo-ui-buttonElement-button:active {
  background-color: rgba(0, 36, 73, 0.082352941176471);
  color: #181818;
  border-color: #72777d;
  box-shadow: none;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-indicatorElement:not(.oo-ui-iconElement):not(.oo-ui-labelElement)
  > .oo-ui-buttonElement-button {
  border-radius: 1px;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-indicatorElement:not(.oo-ui-iconElement):not(.oo-ui-labelElement)
  > .oo-ui-buttonElement-button:focus {
  box-shadow: 0 0 0 2px #ee8ebe;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-indicatorElement:not(.oo-ui-iconElement):not(.oo-ui-labelElement)
  > .oo-ui-buttonElement-button:active {
  box-shadow: none;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive > .oo-ui-buttonElement-button {
  color: #ee8ebe;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button:hover {
  color: #447ff5;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button {
  color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: none;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive > .oo-ui-buttonElement-button {
  color: #d33;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button:hover {
  color: #ff4242;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button {
  color: #b32424;
  border-color: #b32424;
  box-shadow: none;
}
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled[class*='oo-ui-flaggedElement']
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-enabled[class*='oo-ui-flaggedElement']
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-disabled > .oo-ui-buttonElement-button {
  color: #72777d;
}
.wiki-blog .oo-ui-buttonElement-frameless.oo-ui-widget-disabled > .oo-ui-buttonElement-button > .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-buttonElement-frameless.oo-ui-widget-disabled
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  opacity: 0.51;
}
.wiki-blog .oo-ui-buttonElement-framed > .oo-ui-buttonElement-button {
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 12px;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-iconElement > .oo-ui-buttonElement-button {
  padding-top: 2.14285714em;
  padding-bottom: 0;
  padding-left: 2.14285714em;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-iconElement > .oo-ui-buttonElement-button > .oo-ui-iconElement-icon {
  left: 50%;
  margin-left: -0.71428571em;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-iconElement.oo-ui-labelElement > .oo-ui-buttonElement-button,
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-iconElement.oo-ui-indicatorElement > .oo-ui-buttonElement-button {
  padding-left: 2.71428571em;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-iconElement.oo-ui-labelElement
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-iconElement.oo-ui-indicatorElement
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon {
  left: 0.78571429em;
  margin-left: 0;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-indicatorElement > .oo-ui-buttonElement-button {
  padding-top: 2.14285714em;
  padding-right: 1.71428571em;
  padding-bottom: 0;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-labelElement > .oo-ui-buttonElement-button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-disabled.oo-ui-buttonElement-active > .oo-ui-buttonElement-button {
  background-color: #919fb9;
}

.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button:hover
  > .oo-ui-iconElement-icon:not(.oo-ui-image-invert),
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button:hover
  > .oo-ui-indicatorElement-indicator:not(.oo-ui-image-invert) {
  opacity: 0.74;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled > .oo-ui-buttonElement-button:focus {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe;
  outline: 1px solid transparent;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-buttonElement-active > .oo-ui-buttonElement-button {
  background-color: #2a4b8d;
  border-color: #2a4b8d;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-buttonElement-active > .oo-ui-buttonElement-button:focus {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe, inset 0 0 0 2px #fff;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled > .oo-ui-buttonElement-button:active,
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-buttonElement-pressed > .oo-ui-buttonElement-button {
  color: #181818;
  border-color: #72777d;
  box-shadow: none;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive > .oo-ui-buttonElement-button {
  color: #ee8ebe;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive > .oo-ui-buttonElement-button:hover {
  border-color: #447ff5;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive > .oo-ui-buttonElement-button:focus {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe;
  outline: 1px solid transparent;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive.oo-ui-buttonElement-active
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-progressive.oo-ui-popupToolGroup-active
  > .oo-ui-buttonElement-button {
  color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: none;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive > .oo-ui-buttonElement-button {
  color: #d73333;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive > .oo-ui-buttonElement-button:hover {
  border-color: #ff4242;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive > .oo-ui-buttonElement-button:focus {
  border-color: #d33;
  box-shadow: inset 0 0 0 1px #d33;
  outline: 1px solid transparent;
}
.wiki-blog .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive.oo-ui-buttonElement-active
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-destructive.oo-ui-popupToolGroup-active
  > .oo-ui-buttonElement-button {
  color: #b32424;
  border-color: #b32424;
  box-shadow: none;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button {
  border-color: #ee8ebe;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button:hover {
  background-color: #447ff5;
  border-color: #447ff5;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button:focus {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe, inset 0 0 0 2px #fff;
  outline: 1px solid transparent;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive
  > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive.oo-ui-buttonElement-active
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-progressive.oo-ui-popupToolGroup-active
  > .oo-ui-buttonElement-button {
  color: #fff;
  background-color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: none;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button {
  background-color: #d33;
  border-color: #d33;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button:hover {
  background-color: #ff4242;
  border-color: #ff4242;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button:focus {
  border-color: #d33;
  box-shadow: inset 0 0 0 1px #d33, inset 0 0 0 2px #fff;
  outline: 1px solid transparent;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive
  > .oo-ui-buttonElement-button:active,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive.oo-ui-buttonElement-pressed
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive.oo-ui-buttonElement-active
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary.oo-ui-flaggedElement-destructive.oo-ui-popupToolGroup-active
  > .oo-ui-buttonElement-button {
  color: #fff;
  background-color: #b32424;
  border-color: #b32424;
  box-shadow: none;
}
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary
  > .oo-ui-buttonElement-button
  > .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-buttonElement-framed.oo-ui-widget-enabled.oo-ui-flaggedElement-primary
  > .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-clippableElement-clippable {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 40px;
  -webkit-overflow-scrolling: touch;
}
.wiki-blog .oo-ui-floatableElement {
  position: absolute;
}
.wiki-blog .oo-ui-labelElement .oo-ui-labelElement-label,
.wiki-blog .oo-ui-labelElement.oo-ui-labelElement-label {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wiki-blog .oo-ui-labelElement-invisible {
  display: block;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
}
.wiki-blog .oo-ui-labelElement .oo-ui-labelElement-label {
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-labelElement .oo-ui-labelElement-label-highlight {
  font-weight: bold;
}
.wiki-blog .oo-ui-iconElement-icon {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  min-width: 20px;
  width: 1.42857143em;
  min-height: 20px;
  height: 100%;
}
.wiki-blog .oo-ui-iconElement-noIcon {
  display: none;
}
.wiki-blog .oo-ui-indicatorElement-indicator {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  min-width: 12px;
  width: 0.85714286em;
  min-height: 12px;
  height: 100%;
}
.wiki-blog .oo-ui-indicatorElement-noIndicator {
  display: none;
}
.wiki-blog .oo-ui-pendingElement-pending {
  background-color: #eaecf0;
  background-image: linear-gradient(
    135deg,
    #fff 25%,
    transparent 25%,
    transparent 50%,
    #fff 50%,
    #fff 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.42857143em 1.42857143em;
  -webkit-animation: oo-ui-pendingElement-stripes 650ms linear infinite;
  animation: oo-ui-pendingElement-stripes 650ms linear infinite;
}
@-webkit-keyframes oo-ui-pendingElement-stripes {
  0% {
    background-position: -1.42857143em 0;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes oo-ui-pendingElement-stripes {
  0% {
    background-position: -1.42857143em 0;
  }
  100% {
    background-position: 0 0;
  }
}
.wiki-blog .oo-ui-fieldLayout {
  display: block;
  margin-top: 16px;
}
.wiki-blog .oo-ui-fieldLayout:before,
.wiki-blog .oo-ui-fieldLayout:after {
  content: ' ';
  display: table;
}
.wiki-blog .oo-ui-fieldLayout:after {
  clear: both;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body:after,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body:after {
  content: ' ';
  display: block;
  clear: both;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header {
  word-wrap: break-word;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-help,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-help,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-field,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-field {
  display: block;
  float: left;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-right > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header {
  text-align: right;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline {
  word-wrap: break-word;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body {
  display: table;
  width: 100%;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-field {
  display: table-cell;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header {
  vertical-align: middle;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-field {
  width: 1px;
  vertical-align: top;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-top > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-top > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-field {
  display: block;
}
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help {
  float: right;
}
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help:not(.oo-ui-popupButtonWidget) > .oo-ui-buttonElement-button {
  cursor: help;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-labelElement,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline {
  margin-top: 12px;
}
.wiki-blog .oo-ui-fieldLayout:first-child,
.wiki-blog .oo-ui-fieldLayout.oo-ui-labelElement:first-child,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline:first-child {
  margin-top: 0;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-labelElement > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header {
  padding-bottom: 4px;
}
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-top
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header,
.wiki-blog .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-inline > .oo-ui-fieldLayout-body {
  max-width: 50em;
}
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-left
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header,
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-right
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 40%;
  padding-right: 2.64285714em;
}
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-left
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header
  > .oo-ui-labelElement-label,
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-right
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header
  > .oo-ui-labelElement-label {
  display: block;
  padding-top: 4px;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-left > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-help,
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-right
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-help {
  margin-right: 0;
  margin-left: -2.35714286em;
}
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-left
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-field,
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-right
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-field {
  width: 60%;
}
.wiki-blog
  .oo-ui-fieldLayout.oo-ui-labelElement.oo-ui-fieldLayout-align-inline
  > .oo-ui-fieldLayout-body
  > .oo-ui-fieldLayout-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
}
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help {
  margin-right: 0;
}
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help .oo-ui-buttonElement-button {
  padding-top: 1.42857143em;
  padding-right: 0;
}
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help .oo-ui-buttonElement-button:hover,
.wiki-blog .oo-ui-fieldLayout .oo-ui-fieldLayout-help .oo-ui-buttonElement-button:active {
  background-color: transparent;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-top > .oo-ui-fieldLayout-body > .oo-ui-inline-help {
  margin-top: 4px;
}
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-top .oo-ui-fieldLayout-help,
.wiki-blog .oo-ui-fieldLayout.oo-ui-fieldLayout-align-inline .oo-ui-fieldLayout-help {
  margin-top: -6px;
  margin-right: -8px;
  margin-left: 0;
}
.wiki-blog .oo-ui-fieldLayout-messages {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 50em;
  padding: 4px 0;
}
.wiki-blog .oo-ui-fieldLayout-messages > .oo-ui-messageWidget {
  margin-left: 12px;
  margin-right: 12px;
}
.wiki-blog .oo-ui-fieldLayout-messages > .oo-ui-messageWidget:first-child {
  margin-top: 4px;
}
.wiki-blog .oo-ui-fieldLayout-disabled > .oo-ui-fieldLayout-body > .oo-ui-fieldLayout-header > .oo-ui-labelElement-label {
  color: #72777d;
}
.wiki-blog .oo-ui-actionFieldLayout-input,
.wiki-blog .oo-ui-actionFieldLayout-button {
  display: table-cell;
  vertical-align: middle;
}
.wiki-blog .oo-ui-actionFieldLayout-button {
  width: 1%;
  white-space: nowrap;
}
.wiki-blog .oo-ui-actionFieldLayout.oo-ui-fieldLayout-align-top {
  max-width: 50em;
}
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-widget {
  margin-right: 8px;
}
.wiki-blog
  .oo-ui-actionFieldLayout
  .oo-ui-actionFieldLayout-input
  > .oo-ui-widget.oo-ui-textInputWidget
  > .oo-ui-inputWidget-input,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-widget .oo-ui-dropdownWidget-handle,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-widget .oo-ui-tagMultiselectWidget-handle {
  border-radius: 2px 0 0 2px;
  position: relative;
}
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-button .oo-ui-buttonElement-framed > .oo-ui-buttonElement-button {
  border-radius: 0 2px 2px 0;
  margin-left: -1px;
}
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-button .oo-ui-buttonElement-frameless {
  margin-left: 6px;
}
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-textInputWidget > .oo-ui-inputWidget-input:hover,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-textInputWidget > .oo-ui-inputWidget-input:focus,
.wiki-blog
  .oo-ui-actionFieldLayout
  .oo-ui-actionFieldLayout-input
  > .oo-ui-textInputWidget.oo-ui-flaggedElement-invalid
  > .oo-ui-inputWidget-input,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-textInputWidget > .oo-ui-inputWidget-input:hover ~ *,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-input > .oo-ui-textInputWidget > .oo-ui-inputWidget-input:focus ~ *,
.wiki-blog
  .oo-ui-actionFieldLayout
  .oo-ui-actionFieldLayout-input
  > .oo-ui-textInputWidget.oo-ui-flaggedElement-invalid
  > .oo-ui-inputWidget-input
  ~ * {
  z-index: 1;
}
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-button > .oo-ui-buttonElement > .oo-ui-buttonElement-button:hover,
.wiki-blog .oo-ui-actionFieldLayout .oo-ui-actionFieldLayout-button > .oo-ui-buttonElement > .oo-ui-buttonElement-button:focus {
  z-index: 1;
}
.wiki-blog .oo-ui-fieldsetLayout {
  position: relative;
  min-width: 0;
  margin: 0;
  border: 0;
  padding: 0.01px 0 0 0;
}
.wiki-blog body:not(:-moz-handler-blocked) .oo-ui-fieldsetLayout {
  display: table-cell;
}
.wiki-blog .oo-ui-fieldsetLayout > .oo-ui-fieldsetLayout-header {
  display: none;
}
.wiki-blog .oo-ui-fieldsetLayout.oo-ui-iconElement > .oo-ui-fieldsetLayout-header,
.wiki-blog .oo-ui-fieldsetLayout.oo-ui-labelElement > .oo-ui-fieldsetLayout-header {
  color: inherit;
  display: inline-table;
  box-sizing: border-box;
  padding: 0;
  white-space: normal;
  float: left;
  width: 100%;
}
.wiki-blog .oo-ui-fieldsetLayout > .oo-ui-inline-help {
  clear: left;
}
.wiki-blog .oo-ui-fieldsetLayout-group {
  clear: both;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help {
  float: right;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help:not(.oo-ui-popupButtonWidget) > .oo-ui-buttonElement-button {
  cursor: help;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-header {
  max-width: 50em;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-header .oo-ui-iconElement-icon {
  height: 1.42857143em;
}
.wiki-blog .oo-ui-fieldsetLayout.oo-ui-iconElement > .oo-ui-fieldsetLayout-header .oo-ui-iconElement-icon {
  display: block;
}
.wiki-blog .oo-ui-fieldsetLayout + .oo-ui-fieldsetLayout,
.wiki-blog .oo-ui-fieldsetLayout + .oo-ui-formLayout {
  margin-top: 24px;
}
.wiki-blog .oo-ui-fieldsetLayout.oo-ui-labelElement > .oo-ui-fieldsetLayout-header > .oo-ui-labelElement-label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 1.14285714em;
  font-weight: bold;
}
.wiki-blog .oo-ui-fieldsetLayout.oo-ui-iconElement > .oo-ui-fieldsetLayout-header > .oo-ui-labelElement-label {
  padding-left: 1.625em;
}
.wiki-blog .oo-ui-fieldsetLayout > .oo-ui-inline-help {
  margin-bottom: 8px;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help,
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help:last-child {
  margin-right: -8px;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help .oo-ui-buttonElement-button {
  padding-top: 1.42857143em;
  padding-right: 0;
}
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help .oo-ui-buttonElement-button:hover,
.wiki-blog .oo-ui-fieldsetLayout .oo-ui-fieldsetLayout-help .oo-ui-buttonElement-button:active {
  background-color: transparent;
}
.wiki-blog .oo-ui-formLayout + .oo-ui-fieldsetLayout,
.wiki-blog .oo-ui-formLayout + .oo-ui-formLayout {
  margin-top: 24px;
}
.wiki-blog .oo-ui-panelLayout {
  position: relative;
}
.wiki-blog .oo-ui-panelLayout-scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.wiki-blog .oo-ui-panelLayout-expanded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wiki-blog .oo-ui-panelLayout-padded {
  padding: 12px 16px 16px;
}
.wiki-blog .oo-ui-panelLayout-padded.oo-ui-formLayout > .oo-ui-fieldsetLayout .oo-ui-labelElement-label,
.wiki-blog .oo-ui-panelLayout-padded.oo-ui-formLayout > .oo-ui-fieldsetLayout .oo-ui-iconElement-icon {
  margin-top: -6px;
}
.wiki-blog .oo-ui-panelLayout-framed {
  border: 1px solid #a2a9b1;
  border-radius: 2px;
}
.wiki-blog .oo-ui-panelLayout-padded.oo-ui-panelLayout-framed {
  margin: 12px 0;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-widget {
  display: inline-block;
  vertical-align: middle;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-layout {
  display: inline-block;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-layout,
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-widget {
  margin-right: 8px;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-layout:last-child,
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-widget:last-child {
  margin-right: 0;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-layout {
  margin-top: 0;
}
.wiki-blog .oo-ui-horizontalLayout > .oo-ui-widget {
  margin-bottom: 8px;
}
.wiki-blog .oo-ui-optionWidget {
  position: relative;
  display: block;
}
.wiki-blog .oo-ui-optionWidget.oo-ui-widget-enabled {
  cursor: pointer;
}
.wiki-blog .oo-ui-optionWidget.oo-ui-widget-disabled {
  cursor: default;
}
.wiki-blog .oo-ui-optionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wiki-blog .oo-ui-optionWidget-selected .oo-ui-buttonElement-button > .oo-ui-iconElement-icon {
  opacity: 1;
}
.wiki-blog .oo-ui-optionWidget.oo-ui-widget-disabled {
  color: #72777d;
}
.wiki-blog .oo-ui-decoratedOptionWidget {
  padding: 6px 12px;
  line-height: 1;
}
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-iconElement {
  padding-left: 2.64285714em;
}
.wiki-blog .oo-ui-decoratedOptionWidget .oo-ui-iconElement-icon {
  left: 0.78571429em;
}
.wiki-blog .oo-ui-decoratedOptionWidget .oo-ui-labelElement-label {
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-indicatorElement {
  padding-right: 2.14285714em;
}
.wiki-blog .oo-ui-decoratedOptionWidget .oo-ui-indicatorElement-indicator {
  right: 12px;
}
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-enabled:hover .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-enabled:hover .oo-ui-indicatorElement-indicator {
  opacity: 0.74;
}
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-enabled .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-enabled .oo-ui-indicatorElement-indicator {
  opacity: 0.87;
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
}
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-disabled .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-decoratedOptionWidget.oo-ui-widget-disabled .oo-ui-indicatorElement-indicator {
  opacity: 0.51;
}
.wiki-blog .oo-ui-radioSelectWidget:focus {
  outline: 0;
}
.wiki-blog .oo-ui-radioSelectWidget:focus [type='radio']:checked + span:before {
  border-color: #fff;
}
.wiki-blog .oo-ui-radioOptionWidget {
  display: table;
  padding: 4px 0;
}
.wiki-blog .oo-ui-radioOptionWidget .oo-ui-radioInputWidget,
.wiki-blog .oo-ui-radioOptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  display: table-cell;
  vertical-align: top;
}
.wiki-blog .oo-ui-radioOptionWidget .oo-ui-radioInputWidget {
  width: 1px;
}
.wiki-blog .oo-ui-radioOptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  white-space: normal;
}
.wiki-blog .oo-ui-radioOptionWidget:first-child {
  padding-top: 0;
}
.wiki-blog .oo-ui-radioOptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  padding-left: 6px;
}
.wiki-blog .oo-ui-radioOptionWidget .oo-ui-radioInputWidget {
  margin-right: 0;
}
.wiki-blog .oo-ui-labelWidget {
  display: inline-block;
}
.wiki-blog .oo-ui-labelWidget.oo-ui-inline-help {
  display: block;
  color: #54595d;
  font-size: 0.92857143em;
}
.wiki-blog .oo-ui-messageWidget {
  position: relative;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: bold;
}
.wiki-blog .oo-ui-messageWidget > .oo-ui-labelElement-label {
  display: block;
}
.wiki-blog .oo-ui-messageWidget > .oo-ui-iconElement-icon {
  background-position: 0 0;
}
.wiki-blog .oo-ui-messageWidget > .oo-ui-labelElement-label {
  margin-left: 2em;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block {
  border: 1px solid;
  padding: 16px 24px;
  font-weight: normal;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block > .oo-ui-iconElement-icon {
  background-position: 0 16px;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block.oo-ui-flaggedElement-error {
  background-color: #fee7e6;
  border-color: #d33;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block.oo-ui-flaggedElement-warning {
  background-color: #fef6e7;
  border-color: #fc3;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block.oo-ui-flaggedElement-success {
  background-color: #d5fdf4;
  border-color: #14866d;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-messageWidget-block.oo-ui-flaggedElement-notice {
  background-color: #eaecf0;
  border-color: #a2a9b1;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-flaggedElement-error:not(.oo-ui-messageWidget-block) {
  color: #d33;
}
.wiki-blog .oo-ui-messageWidget.oo-ui-flaggedElement-success:not(.oo-ui-messageWidget-block) {
  color: #14866d;
}
.wiki-blog .oo-ui-messageWidget + .oo-ui-messageWidget {
  margin-top: 8px;
}
.wiki-blog .oo-ui-iconWidget {
  vertical-align: middle;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  clip: auto;
  margin: 0;
  text-indent: -9999px;
  line-height: 2.5;
  display: inline-block;
  position: static;
  top: auto;
  height: 1.42857143em;
}
.wiki-blog .oo-ui-iconWidget.oo-ui-widget-disabled {
  opacity: 0.51;
}
.wiki-blog .oo-ui-indicatorWidget {
  vertical-align: middle;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  clip: auto;
  margin: 0;
  text-indent: -9999px;
  line-height: 2.5;
  display: inline-block;
  position: static;
  top: auto;
  height: 0.85714286em;
}
.wiki-blog .oo-ui-indicatorWidget.oo-ui-widget-disabled {
  opacity: 0.51;
}
.wiki-blog .oo-ui-buttonWidget {
  margin-right: 8px;
}
.wiki-blog .oo-ui-buttonWidget:last-child {
  margin-right: 0;
}
.wiki-blog .oo-ui-buttonGroupWidget {
  display: inline-block;
  border-radius: 2px;
  margin-right: 8px;
  z-index: 0;
  position: relative;
  padding-bottom: 1px;
}
.wiki-blog .oo-ui-buttonGroupWidget .oo-ui-buttonWidget.oo-ui-buttonElement-active .oo-ui-buttonElement-button {
  cursor: default;
}
.wiki-blog .oo-ui-buttonGroupWidget:last-child {
  margin-right: 0;
}
.wiki-blog .oo-ui-buttonGroupWidget .oo-ui-buttonElement {
  margin-right: 0;
  z-index: 0;
}
.wiki-blog .oo-ui-buttonGroupWidget .oo-ui-buttonElement-framed .oo-ui-buttonElement-button {
  margin-right: -1px;
  margin-bottom: -1px;
  border-radius: 0;
}
.wiki-blog .oo-ui-buttonGroupWidget .oo-ui-buttonElement-framed:first-child .oo-ui-buttonElement-button {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.wiki-blog .oo-ui-buttonGroupWidget .oo-ui-buttonElement-framed:last-child .oo-ui-buttonElement-button {
  margin-right: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.wiki-blog
  .oo-ui-buttonGroupWidget
  .oo-ui-buttonElement-framed.oo-ui-widget-disabled
  + .oo-ui-widget-disabled
  > .oo-ui-buttonElement-button {
  border-left-color: #fff;
}
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button:active {
  z-index: 1;
}
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-widget-enabled
  > .oo-ui-buttonElement-button:focus {
  z-index: 2;
}
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-buttonElement-active
  > .oo-ui-buttonElement-button {
  z-index: 3;
}
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-widget-disabled
  > .oo-ui-buttonElement-button {
  z-index: -1;
}
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-toggleWidget-on
  + .oo-ui-toggleWidget-on
  > .oo-ui-buttonElement-button,
.wiki-blog
  .oo-ui-buttonGroupWidget.oo-ui-widget-enabled
  .oo-ui-buttonElement.oo-ui-toggleWidget-on
  + .oo-ui-toggleWidget-on
  > .oo-ui-buttonElement-button:active {
  border-left-color: #a2a9b1;
  z-index: 3;
}
.wiki-blog .oo-ui-popupWidget {
  position: absolute;
  z-index: 1;
}
.wiki-blog .oo-ui-popupWidget-popup {
  position: relative;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.wiki-blog .oo-ui-popupWidget-anchor {
  display: none;
}
.wiki-blog .oo-ui-popupWidget-anchored .oo-ui-popupWidget-anchor {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
}
.wiki-blog .oo-ui-popupWidget-anchored .oo-ui-popupWidget-anchor:before,
.wiki-blog .oo-ui-popupWidget-anchored .oo-ui-popupWidget-anchor:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor {
  left: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor:before,
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor:after {
  border-top: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor {
  left: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor:before,
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor:after {
  border-bottom: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor {
  top: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor:before,
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor:after {
  border-left: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor {
  top: 0;
}
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor:before,
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor:after {
  border-right: 0;
}
.wiki-blog .oo-ui-popupWidget-head {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-labelElement-label {
  cursor: default;
}
.wiki-blog .oo-ui-popupWidget-body {
  clear: both;
}
.wiki-blog .oo-ui-popupWidget-body.oo-ui-clippableElement-clippable {
  min-height: 1em;
}
.wiki-blog .oo-ui-popupWidget-popup {
  background-color: #fff;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}
@supports (filter: drop-shadow(0 0 0)) {
  .wiki-blog .oo-ui-popupWidget {
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .wiki-blog .oo-ui-popupWidget-popup {
    box-shadow: none;
  }
}
.wiki-blog .oo-ui-popupWidget-anchored-top {
  margin-top: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor {
  top: -9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor:before {
  bottom: -10px;
  left: -9px;
  border-bottom-color: #7b8590;
  border-width: 10px;
}
.wiki-blog .oo-ui-popupWidget-anchored-top .oo-ui-popupWidget-anchor:after {
  bottom: -10px;
  left: -8px;
  border-bottom-color: #fff;
  border-width: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom {
  margin-bottom: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor {
  bottom: -9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor:before {
  top: -10px;
  left: -9px;
  border-top-color: #a2a9b1;
  border-width: 10px;
}
.wiki-blog .oo-ui-popupWidget-anchored-bottom .oo-ui-popupWidget-anchor:after {
  top: -10px;
  left: -8px;
  border-top-color: #fff;
  border-width: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-start {
  margin-left: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor {
  left: -9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor:before {
  right: -10px;
  top: -9px;
  border-right-color: #a2a9b1;
  border-width: 10px;
}
.wiki-blog .oo-ui-popupWidget-anchored-start .oo-ui-popupWidget-anchor:after {
  right: -10px;
  top: -8px;
  border-right-color: #fff;
  border-width: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-end {
  margin-right: 9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor {
  right: -9px;
}
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor:before {
  left: -10px;
  top: -9px;
  border-left-color: #a2a9b1;
  border-width: 10px;
}
.wiki-blog .oo-ui-popupWidget-anchored-end .oo-ui-popupWidget-anchor:after {
  left: -10px;
  top: -8px;
  border-left-color: #fff;
  border-width: 9px;
}
.wiki-blog .oo-ui-popupWidget-transitioning .oo-ui-popupWidget-popup {
  -webkit-transition: width 100ms, height 100ms, left 100ms;
  transition: width 100ms, height 100ms, left 100ms;
}
.wiki-blog .oo-ui-popupWidget-head {
  margin-bottom: 9px;
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-iconElement-icon {
  left: 0.78571429em;
  height: calc(1.42857143em + 2 * 9px);
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-iconElement-noIcon + .oo-ui-labelElement-label {
  margin-left: 12px;
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-labelElement-label {
  display: inline-block;
  margin: 9px 12px 0 2.64285714em;
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-buttonWidget {
  position: absolute;
  right: 0;
}
.wiki-blog .oo-ui-popupWidget-head > .oo-ui-buttonWidget .oo-ui-icon-close {
  background-size: 1.14285714em 1.14285714em;
}
.wiki-blog .oo-ui-popupWidget-body {
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-popupWidget-body-padded {
  margin: 5px 12px;
}
.wiki-blog .oo-ui-popupWidget-footer {
  margin: 9px 12px;
}
.wiki-blog .oo-ui-popupButtonWidget {
  position: relative;
}
.wiki-blog .oo-ui-popupButtonWidget .oo-ui-popupWidget {
  cursor: auto;
}
.wiki-blog .oo-ui-inputWidget {
  margin-right: 8px;
}
.wiki-blog .oo-ui-inputWidget:last-child {
  margin-right: 0;
}
.wiki-blog .oo-ui-buttonInputWidget > button,
.wiki-blog .oo-ui-buttonInputWidget > input {
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
}
.wiki-blog .oo-ui-checkboxInputWidget {
  display: inline-block;
  z-index: 0;
  position: relative;
  line-height: 1.42857143em;
  white-space: nowrap;
}
.wiki-blog .oo-ui-checkboxInputWidget * {
  font: inherit;
  vertical-align: middle;
}
.wiki-blog .oo-ui-checkboxInputWidget [type='checkbox'] {
  position: relative;
  max-width: none;
  width: 1.42857143em;
  height: 1.42857143em;
  margin: 0;
  opacity: 0;
  z-index: 1;
}
.wiki-blog .oo-ui-checkboxInputWidget [type='checkbox'] + span {
  background-color: #fff;
  background-size: 0 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 1.42857143em;
  height: 1.42857143em;
  border-color: #72777d;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
}
.wiki-blog .oo-ui-checkboxInputWidget [type='checkbox']:checked:not(:indeterminate) + span {
  background-size: 1em 1em;
}
.wiki-blog .oo-ui-checkboxInputWidget [type='checkbox']:indeterminate + span:before {
  content: ' ';
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0.21428571em;
  right: 0.21428571em;
  height: 2px;
  margin-top: -1px;
}
.wiki-blog .oo-ui-checkboxInputWidget [type='checkbox']:disabled + span {
  background-color: #c8ccd1;
  border-color: #c8ccd1;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox'] {
  cursor: pointer;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox'] + span {
  cursor: pointer;
  -webkit-transition: background-color 100ms, border-color 100ms, box-shadow 100ms;
  transition: background-color 100ms, border-color 100ms, box-shadow 100ms;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:focus + span {
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe;
  outline: 1px solid transparent;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:hover + span {
  border-color: #447ff5;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:active + span {
  background-color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: inset 0 0 0 1px #2a4b8d;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:checked + span,
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:indeterminate + span {
  background-color: #ee8ebe;
  border-color: #ee8ebe;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:checked:focus + span,
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:indeterminate:focus + span {
  background-color: #ee8ebe;
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe, inset 0 0 0 2px #fff;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:checked:hover + span,
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:indeterminate:hover + span {
  background-color: #447ff5;
  border-color: #447ff5;
}
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:checked:active + span,
.wiki-blog .oo-ui-checkboxInputWidget.oo-ui-widget-enabled [type='checkbox']:indeterminate:active + span {
  background-color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: inset 0 0 0 1px #2a4b8d;
}
.wiki-blog .oo-ui-checkboxMultiselectInputWidget .oo-ui-fieldLayout {
  margin-top: 0;
  padding: 4px 0;
}
.wiki-blog .oo-ui-checkboxMultiselectInputWidget .oo-ui-fieldLayout:first-child {
  padding-top: 0;
}
.wiki-blog .oo-ui-dropdownInputWidget {
  position: relative;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 50em;
}
.wiki-blog .oo-ui-dropdownInputWidget .oo-ui-dropdownWidget,
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-dropdownInputWidget-php select,
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-isMobile select {
  display: block;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-isMobile .oo-ui-dropdownWidget {
  display: none;
}
.wiki-blog .oo-ui-dropdownInputWidget select {
  display: none;
  background-position: -9999em 0;
  background-repeat: no-repeat;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled select {
  cursor: pointer;
}
.wiki-blog .oo-ui-dropdownInputWidget select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-position: right 12px center;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 2.28571429em;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 2.14285714em;
  font-size: inherit;
  font-family: inherit;
  vertical-align: middle;
  background-position: -9999em 0 \9;
  padding: 0 \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wiki-blog .oo-ui-dropdownInputWidget select {
    background-position: right 12px center;
    padding-left: 12px;
    padding-right: 2.14285714em;
  }
}
.wiki-blog .oo-ui-dropdownInputWidget select::-ms-expand {
  display: none;
}
.wiki-blog .oo-ui-dropdownInputWidget option {
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  height: 1.5em;
  padding: 5px 12px;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled {
  -webkit-transition: background-color 100ms;
  transition: background-color 100ms;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled:hover {
  background-color: #fff;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled select {
  color: #202122;
  -webkit-transition: border-color 100ms, box-shadow 100ms;
  transition: border-color 100ms, box-shadow 100ms;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled select:hover {
  color: #404244;
  border-color: #a2a9b1;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled select:active {
  color: #181818;
  border-color: #72777d;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-enabled select:focus {
  border-color: #ee8ebe;
  outline: 0;
  box-shadow: inset 0 0 0 1px #ee8ebe;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-disabled {
  background-color: #eaecf0;
}
.wiki-blog .oo-ui-dropdownInputWidget.oo-ui-widget-disabled select {
  color: #72777d;
  border-color: #c8ccd1;
}
.wiki-blog .oo-ui-radioInputWidget {
  display: inline-block;
  z-index: 0;
  position: relative;
  line-height: 1.42857143em;
  white-space: nowrap;
}
.wiki-blog .oo-ui-radioInputWidget * {
  font: inherit;
  vertical-align: middle;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio'] {
  position: relative;
  max-width: none;
  width: 1.42857143em;
  height: 1.42857143em;
  margin: 0;
  opacity: 0;
  z-index: 1;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio'] + span {
  background-color: #fff;
  position: absolute;
  left: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 1.42857143em;
  height: 1.42857143em;
  border-color: #72777d;
  border-style: solid;
  border-radius: 50%;
  border-width: 0;
  border-width: 1px;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio'] + span:before {
  content: ' ';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 1px solid transparent;
  border-radius: 50%;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio']:checked + span,
.wiki-blog .oo-ui-radioInputWidget [type='radio']:checked:hover + span,
.wiki-blog .oo-ui-radioInputWidget [type='radio']:checked:focus:hover + span {
  border-width: 6px;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio']:disabled + span {
  background-color: #c8ccd1;
  border-color: #c8ccd1;
}
.wiki-blog .oo-ui-radioInputWidget [type='radio']:disabled:checked + span {
  background-color: #fff;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio'] {
  cursor: pointer;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio'] + span {
  cursor: pointer;
  -webkit-transition: background-color 100ms, border-color 100ms, border-width 100ms;
  transition: background-color 100ms, border-color 100ms, border-width 100ms;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:hover + span {
  border-color: #447ff5;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:active + span {
  background-color: #2a4b8d;
  border-color: #2a4b8d;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:checked + span {
  border-color: #ee8ebe;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:checked:focus + span:before {
  border-color: #fff;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:checked:hover + span {
  border-color: #447ff5;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:checked:active + span {
  border-color: #2a4b8d;
  box-shadow: inset 0 0 0 1px #2a4b8d;
}
.wiki-blog .oo-ui-radioInputWidget.oo-ui-widget-enabled [type='radio']:checked:active + span:before {
  border-color: #2a4b8d;
}
.wiki-blog .oo-ui-radioSelectInputWidget .oo-ui-fieldLayout {
  margin-top: 0;
  padding: 4px 0;
}
.wiki-blog .oo-ui-radioSelectInputWidget .oo-ui-fieldLayout:first-child {
  padding-top: 0;
}
.wiki-blog .oo-ui-textInputWidget {
  position: relative;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 50em;
}
.wiki-blog .oo-ui-textInputWidget .oo-ui-inputWidget-input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  display: block;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wiki-blog .oo-ui-textInputWidget input::-ms-clear {
  display: none;
}
.wiki-blog .oo-ui-textInputWidget textarea {
  overflow: auto;
}
.wiki-blog .oo-ui-textInputWidget textarea.oo-ui-textInputWidget-autosized {
  resize: none;
}
.wiki-blog .oo-ui-textInputWidget [type='number']::-webkit-outer-spin-button,
.wiki-blog .oo-ui-textInputWidget [type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wiki-blog .oo-ui-textInputWidget [type='search']::-webkit-search-decoration,
.wiki-blog .oo-ui-textInputWidget [type='search']::-webkit-search-cancel-button {
  display: none;
}
.wiki-blog .oo-ui-textInputWidget > .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-textInputWidget-labelPosition-before > .oo-ui-labelElement-label {
  left: 0;
}
.wiki-blog .oo-ui-textInputWidget > .oo-ui-indicatorElement-indicator,
.wiki-blog .oo-ui-textInputWidget-labelPosition-after > .oo-ui-labelElement-label {
  right: 0;
}
.wiki-blog .oo-ui-textInputWidget > .oo-ui-labelElement-label {
  position: absolute;
  top: 0;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  display: block;
}
.wiki-blog .oo-ui-textInputWidget-labelPosition-after.oo-ui-labelElement ::-ms-clear {
  display: none;
}
.wiki-blog .oo-ui-textInputWidget-php > .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-textInputWidget-php > .oo-ui-indicatorElement-indicator,
.wiki-blog .oo-ui-textInputWidget-php > .oo-ui-labelElement-label {
  pointer-events: none;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled > .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled > .oo-ui-indicatorElement-indicator {
  cursor: text;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled.oo-ui-textInputWidget-type-search > .oo-ui-indicatorElement-indicator {
  cursor: pointer;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-disabled > * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.wiki-blog .oo-ui-textInputWidget .oo-ui-inputWidget-input {
  background-color: #fff;
  color: #181818;
  margin: 0;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  padding: 5px 8px;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-textInputWidget input {
  height: 2.28571429em;
}
.wiki-blog .oo-ui-textInputWidget .oo-ui-pendingElement-pending {
  background-color: #eaecf0;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-iconElement .oo-ui-inputWidget-input {
  padding-left: 2.42857143em;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-iconElement > .oo-ui-iconElement-icon {
  left: 9px;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-iconElement textarea + .oo-ui-iconElement-icon {
  max-height: 2.28571429em;
}
.wiki-blog .oo-ui-textInputWidget > .oo-ui-labelElement-label {
  color: #72777d;
  padding: 0 12px 0 8px;
  line-height: 2.28571429em;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-indicatorElement .oo-ui-inputWidget-input {
  padding-right: 28px;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-indicatorElement.oo-ui-textInputWidget-labelPosition-after > .oo-ui-labelElement-label {
  padding-right: 0;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-indicatorElement > .oo-ui-indicatorElement-indicator {
  max-height: 2.28571429em;
  margin-right: 0.71428571em;
}
.wiki-blog .oo-ui-textInputWidget-labelPosition-after.oo-ui-indicatorElement > .oo-ui-labelElement-label {
  margin-right: 2.28571429em;
}
.wiki-blog .oo-ui-textInputWidget-labelPosition-before.oo-ui-iconElement > .oo-ui-labelElement-label {
  padding-left: 2.42857143em;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input {
  box-shadow: inset 0 0 0 1px transparent;
  -webkit-transition: border-color 250ms, box-shadow 250ms;
  transition: border-color 250ms, box-shadow 250ms;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input::-webkit-input-placeholder {
  color: #72777d;
  opacity: 1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input:-ms-input-placeholder {
  color: #72777d;
  opacity: 1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input::-moz-placeholder {
  color: #72777d;
  opacity: 1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input::placeholder {
  color: #72777d;
  opacity: 1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input:focus {
  outline: 0;
  border-color: #ee8ebe;
  box-shadow: inset 0 0 0 1px #ee8ebe;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input:focus ~ .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled .oo-ui-inputWidget-input:focus ~ .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled:hover .oo-ui-inputWidget-input {
  border-color: #72777d;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled:hover .oo-ui-inputWidget-input:focus {
  border-color: #ee8ebe;
}
@media screen and (min-width: 0) {
  .wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled textarea.oo-ui-inputWidget-input:focus {
    outline: 1px solid #ee8ebe;
    outline-offset: -2px;
  }
  .wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled.oo-ui-flaggedElement-invalid textarea.oo-ui-inputWidget-input:focus {
    outline-color: #d33;
  }
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled > .oo-ui-iconElement-icon {
  opacity: 0.67;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled > .oo-ui-indicatorElement-indicator {
  opacity: 0.87;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled.oo-ui-flaggedElement-invalid .oo-ui-inputWidget-input {
  border-color: #d33;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled.oo-ui-flaggedElement-invalid .oo-ui-inputWidget-input:hover {
  border-color: #d33;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-enabled.oo-ui-flaggedElement-invalid .oo-ui-inputWidget-input:focus {
  border-color: #d33;
  box-shadow: inset 0 0 0 1px #d33;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-disabled .oo-ui-inputWidget-input {
  background-color: #eaecf0;
  -webkit-text-fill-color: #72777d;
  color: #72777d;
  text-shadow: 0 1px 1px #fff;
  border-color: #c8ccd1;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-disabled > .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-disabled > .oo-ui-indicatorElement-indicator {
  opacity: 0.51;
}
.wiki-blog .oo-ui-textInputWidget.oo-ui-widget-disabled > .oo-ui-labelElement-label {
  color: #72777d;
  text-shadow: 0 1px 1px #fff;
}
.wiki-blog .oo-ui-menuSelectWidget {
  position: absolute;
  width: 100%;
  z-index: 4;
  background-color: #fff;
  margin-top: -1px;
  margin-bottom: -1px;
  border: 1px solid #a2a9b1;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}
.wiki-blog .oo-ui-menuSelectWidget.oo-ui-clippableElement-clippable {
  min-height: 32px;
}
.wiki-blog .oo-ui-menuSelectWidget-invisible {
  display: none;
}
.wiki-blog .oo-ui-menuOptionWidget {
  -webkit-transition: background-color 100ms, color 100ms;
  transition: background-color 100ms, color 100ms;
}
.wiki-blog .oo-ui-menuOptionWidget-checkIcon {
  display: none;
}
.wiki-blog .oo-ui-menuOptionWidget.oo-ui-optionWidget-highlighted {
  background-color: #eaecf0;
  color: #181818;
}
.wiki-blog .oo-ui-menuOptionWidget.oo-ui-optionWidget-selected {
  background-color: #eaf3ff;
  color: #ee8ebe;
}
.wiki-blog .oo-ui-menuOptionWidget.oo-ui-optionWidget-selected.oo-ui-menuOptionWidget.oo-ui-optionWidget-highlighted,
.wiki-blog .oo-ui-menuOptionWidget.oo-ui-optionWidget-pressed.oo-ui-menuOptionWidget.oo-ui-optionWidget-highlighted {
  background-color: rgba(41, 98, 204, 0.1);
  color: #ee8ebe;
}
.wiki-blog .oo-ui-menuOptionWidget.oo-ui-widget-enabled.oo-ui-optionWidget {
  color: #202122;
}
.wiki-blog .oo-ui-menuSectionOptionWidget {
  color: #72777d;
  padding: 5px 12px 4px;
  font-weight: bold;
}
.wiki-blog .oo-ui-menuSectionOptionWidget.oo-ui-widget-enabled {
  cursor: default;
}
.wiki-blog .oo-ui-menuSectionOptionWidget ~ .oo-ui-menuOptionWidget {
  padding-left: 24px;
}
.wiki-blog .oo-ui-menuSectionOptionWidget ~ .oo-ui-menuOptionWidget.oo-ui-iconElement {
  padding-left: 3.5em;
}
.wiki-blog .oo-ui-menuSectionOptionWidget ~ .oo-ui-menuOptionWidget.oo-ui-iconElement .oo-ui-iconElement-icon {
  left: 1.71428571em;
}
.wiki-blog .oo-ui-dropdownWidget {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  max-width: 50em;
  margin-right: 8px;
}
.wiki-blog .oo-ui-dropdownWidget-handle {
  position: relative;
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wiki-blog .oo-ui-dropdownWidget-handle .oo-ui-labelElement-label {
  display: inline-block;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle {
  cursor: pointer;
}
.wiki-blog .oo-ui-dropdownWidget:last-child {
  margin-right: 0;
}
.wiki-blog .oo-ui-dropdownWidget-handle {
  min-height: 32px;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  padding: 5px 2.14285714em 5px 12px;
  line-height: 1;
}
.wiki-blog .oo-ui-dropdownWidget-handle .oo-ui-iconElement-icon {
  left: 12px;
}
.wiki-blog .oo-ui-dropdownWidget-handle .oo-ui-indicatorElement-indicator {
  right: 11px;
}
.wiki-blog .oo-ui-dropdownWidget-handle .oo-ui-labelElement-label {
  line-height: 1.42857143em;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-iconElement .oo-ui-dropdownWidget-handle {
  padding-left: 2.71428571em;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-indicatorElement .oo-ui-dropdownWidget-handle {
  padding-right: 2.57142857em;
}

.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle:hover .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle:hover .oo-ui-indicatorElement-indicator {
  opacity: 0.74;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle:active {
  color: #181818;
  border-color: #72777d;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle:focus {
  border-color: #ee8ebe;
  outline: 0;
  box-shadow: inset 0 0 0 1px #ee8ebe;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle .oo-ui-iconElement-icon,
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled .oo-ui-dropdownWidget-handle .oo-ui-indicatorElement-indicator {
  opacity: 0.87;
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-enabled.oo-ui-dropdownWidget-open .oo-ui-dropdownWidget-handle {
  background-color: #fff;
}
.wiki-blog
  .oo-ui-dropdownWidget.oo-ui-widget-enabled.oo-ui-dropdownWidget-open
  .oo-ui-dropdownWidget-handle
  .oo-ui-iconElement-icon,
.wiki-blog
  .oo-ui-dropdownWidget.oo-ui-widget-enabled.oo-ui-dropdownWidget-open
  .oo-ui-dropdownWidget-handle
  .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-disabled .oo-ui-dropdownWidget-handle {
  color: #72777d;
  text-shadow: 0 1px 1px #fff;
  border-color: #c8ccd1;
  background-color: #eaecf0;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-disabled .oo-ui-dropdownWidget-handle:focus {
  outline: 0;
}
.wiki-blog .oo-ui-dropdownWidget.oo-ui-widget-disabled .oo-ui-dropdownWidget-handle .oo-ui-indicatorElement-indicator {
  opacity: 0.15;
}
.wiki-blog .oo-ui-comboBoxInputWidget {
  display: inline-block;
  position: relative;
}
.wiki-blog .oo-ui-comboBoxInputWidget-field {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.wiki-blog .oo-ui-comboBoxInputWidget .oo-ui-inputWidget-input {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton {
  display: table-cell;
}
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton > .oo-ui-buttonElement-button {
  display: block;
  overflow: hidden;
}
.wiki-blog .oo-ui-comboBoxInputWidget.oo-ui-comboBoxInputWidget-empty .oo-ui-comboBoxInputWidget-dropdownButton {
  display: none;
}
.wiki-blog .oo-ui-comboBoxInputWidget-php ::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.5em;
  height: 2.5em;
  padding: 0;
}
.wiki-blog .oo-ui-comboBoxInputWidget-php > .oo-ui-indicatorWidget {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;
}
.wiki-blog .oo-ui-comboBoxInputWidget .oo-ui-inputWidget-input {
  height: 2.28571429em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.wiki-blog .oo-ui-comboBoxInputWidget.oo-ui-comboBoxInputWidget-empty .oo-ui-inputWidget-input,
.wiki-blog .oo-ui-comboBoxInputWidget-php .oo-ui-inputWidget-input {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-right-width: 1px;
}
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton.oo-ui-indicatorElement {
  width: 2.64285714em;
}
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton.oo-ui-indicatorElement .oo-ui-buttonElement-button {
  min-width: 37px;
  padding-left: 0;
}
.wiki-blog
  .oo-ui-comboBoxInputWidget-dropdownButton.oo-ui-indicatorElement
  .oo-ui-buttonElement-button
  > .oo-ui-indicatorElement-indicator {
  right: 0.85714286em;
}
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton.oo-ui-indicatorElement .oo-ui-buttonElement-button,
.wiki-blog .oo-ui-comboBoxInputWidget-dropdownButton.oo-ui-indicatorElement .oo-ui-buttonElement-button:focus {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.wiki-blog .oo-ui-comboBoxInputWidget-php .oo-ui-indicatorWidget {
  right: 12px;
  margin: 0;
}
.wiki-blog .oo-ui-comboBoxInputWidget-open .oo-ui-comboBoxInputWidget-dropdownButton > .oo-ui-buttonElement-button {
  background-color: #fff;
}
.wiki-blog
  .oo-ui-comboBoxInputWidget-open
  .oo-ui-comboBoxInputWidget-dropdownButton
  > .oo-ui-buttonElement-button
  .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-comboBoxInputWidget.oo-ui-widget-disabled .oo-ui-indicatorElement-indicator {
  opacity: 1;
}
.wiki-blog .oo-ui-multioptionWidget {
  position: relative;
  display: block;
}
.wiki-blog .oo-ui-multioptionWidget.oo-ui-widget-enabled {
  cursor: pointer;
}
.wiki-blog .oo-ui-multioptionWidget.oo-ui-widget-disabled {
  cursor: default;
}
.wiki-blog .oo-ui-multioptionWidget.oo-ui-labelElement .oo-ui-labelElement-label {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wiki-blog .oo-ui-multioptionWidget.oo-ui-widget-disabled {
  color: #72777d;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget {
  display: table;
  padding: 4px 0;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget .oo-ui-checkboxInputWidget,
.wiki-blog .oo-ui-checkboxMultioptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  display: table-cell;
  vertical-align: top;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget .oo-ui-checkboxInputWidget {
  width: 1px;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  white-space: normal;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget:first-child {
  padding-top: 0;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget.oo-ui-labelElement > .oo-ui-labelElement-label {
  padding-left: 6px;
}
.wiki-blog .oo-ui-checkboxMultioptionWidget .oo-ui-checkboxInputWidget {
  margin-right: 0;
}
.wiki-blog .oo-ui-progressBarWidget {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 50em;
  border: 1px solid #a2a9b1;
  border-radius: 1em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.wiki-blog .oo-ui-progressBarWidget:not(.oo-ui-pendingElement-pending) {
  background-color: #fff;
}
.wiki-blog .oo-ui-progressBarWidget-bar {
  height: 1em;
  -webkit-transition: width 100ms;
  transition: width 100ms;
}
.wiki-blog .oo-ui-progressBarWidget-indeterminate .oo-ui-progressBarWidget-bar {
  -webkit-animation: oo-ui-progressBarWidget-slide 2s infinite linear;
  animation: oo-ui-progressBarWidget-slide 2s infinite linear;
  width: 40%;
  -webkit-transform: translate(-25%);
  -ms-transform: translate(-25%);
  transform: translate(-25%);
}
.wiki-blog .oo-ui-progressBarWidget.oo-ui-widget-enabled .oo-ui-progressBarWidget-bar {
  background-color: #ee8ebe;
}
.wiki-blog .oo-ui-progressBarWidget.oo-ui-widget-disabled .oo-ui-progressBarWidget-bar {
  background-color: #c8ccd1;
}
@-webkit-keyframes oo-ui-progressBarWidget-slide {
  from {
    -webkit-transform: translate(-100%);
    -ms-transform: translate(-100%);
    transform: translate(-100%);
  }
  to {
    -webkit-transform: translate(350%);
    -ms-transform: translate(350%);
    transform: translate(350%);
  }
}
@keyframes oo-ui-progressBarWidget-slide {
  from {
    -webkit-transform: translate(-100%);
    -ms-transform: translate(-100%);
    transform: translate(-100%);
  }
  to {
    -webkit-transform: translate(350%);
    -ms-transform: translate(350%);
    transform: translate(350%);
  }
}
.wiki-blog .oo-ui-numberInputWidget {
  display: inline-block;
  position: relative;
  max-width: 50em;
}
.wiki-blog .oo-ui-numberInputWidget-buttoned .oo-ui-buttonWidget,
.wiki-blog .oo-ui-numberInputWidget-buttoned .oo-ui-inputWidget-input {
  display: table-cell;
  height: 100%;
}
.wiki-blog .oo-ui-numberInputWidget-field {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.wiki-blog .oo-ui-numberInputWidget-buttoned .oo-ui-buttonWidget {
  width: 2.64285714em;
}
.wiki-blog .oo-ui-numberInputWidget-buttoned .oo-ui-buttonWidget .oo-ui-buttonElement-button {
  display: block;
  min-width: 37px;
  min-height: 32px;
  padding-left: 0;
  padding-right: 0;
}
.wiki-blog .oo-ui-numberInputWidget-buttoned .oo-ui-inputWidget-input {
  height: 2.28571429em;
  border-radius: 0;
}
.wiki-blog .oo-ui-numberInputWidget-minusButton > .oo-ui-buttonElement-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.wiki-blog .oo-ui-numberInputWidget-plusButton > .oo-ui-buttonElement-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.wiki-blog .oo-ui-numberInputWidget.oo-ui-widget-disabled.oo-ui-numberInputWidget-buttoned .oo-ui-iconElement-icon {
  opacity: 1;
}
.wiki-blog .oo-ui-selectFileInputWidget {
  width: 100%;
  max-width: 50em;
  min-height: 2.28571429em;
}
.wiki-blog .oo-ui-selectFileInputWidget-selectButton > .oo-ui-buttonElement-button {
  position: relative;
  overflow: hidden;
}
.wiki-blog .oo-ui-selectFileInputWidget-selectButton > .oo-ui-buttonElement-button > [type='file'] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  padding-top: 100px;
}
.wiki-blog .oo-ui-selectFileInputWidget-selectButton.oo-ui-widget-disabled > .oo-ui-buttonElement-button > [type='file'] {
  display: none;
}
.wiki-blog .oo-ui-selectFileInputWidget-info > .oo-ui-inputWidget-input {
  pointer-events: none;
}
.wiki-blog .oo-ui-selectFileInputWidget-empty.oo-ui-widget-enabled .oo-ui-selectFileInputWidget-label {
  cursor: default;
}
.wiki-blog .oo-ui-selectFileInputWidget-info > .oo-ui-inputWidget-input {
  height: 2.28571429em;
}
.wiki-blog .oo-ui-defaultOverlay {
  position: absolute;
  top: 0;
  left: 0;
}
.wiki-blog .oo-ui-defaultOverlay,
.wiki-blog .skin-vector .oo-ui-windowManager-modal > .oo-ui-dialog,
.wiki-blog .skin-vector .ve-ui-overlay-global {
  z-index: 101;
}
.wiki-blog body > .oo-ui-windowManager,
.wiki-blog .oo-ui-defaultOverlay {
  font-size: 0.875em;
}
.wiki-blog .oo-ui-indicator-clear {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=clear&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclear%3C/title%3E%3Cpath d=%22M10 0a10 10 0 1010 10A10 10 0 0010 0zm5.66 14.24l-1.41 1.41L10 11.41l-4.24 4.25-1.42-1.42L8.59 10 4.34 5.76l1.42-1.42L10 8.59l4.24-4.24 1.41 1.41L11.41 10z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-indicator-clear {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=clear&variant=invert&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eclear%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M10 0a10 10 0 1010 10A10 10 0 0010 0zm5.66 14.24l-1.41 1.41L10 11.41l-4.24 4.25-1.42-1.42L8.59 10 4.34 5.76l1.42-1.42L10 8.59l4.24-4.24 1.41 1.41L11.41 10z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-indicator-up {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=up&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22%3E%3Ctitle%3Eup%3C/title%3E%3Cpath d=%22M10.035 9.026L6 5.168 2.053 9.026 1 7.974l5-5 5 5z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-indicator-up {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=up&variant=invert&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22%3E%3Ctitle%3Eup%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M10.035 9.026L6 5.168 2.053 9.026 1 7.974l5-5 5 5z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-indicator-down {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=down&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22%3E%3Ctitle%3Edown%3C/title%3E%3Cpath d=%22M10.085 2.943L6.05 6.803l-3.947-3.86L1.05 3.996l5 5 5-5z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-indicator-down {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=down&variant=invert&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22%3E%3Ctitle%3Edown%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M10.085 2.943L6.05 6.803l-3.947-3.86L1.05 3.996l5 5 5-5z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-indicator-required {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=required&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Erequired%3C/title%3E%3Cpath d=%22M8.5 0h3v20h-3z%22/%3E%3Cpath d=%22M19.41 13.701l-1.5 2.598L.59 6.3l1.5-2.598z%22/%3E%3Cpath d=%22M17.91 3.701l1.5 2.598-17.32 10-1.5-2.598z%22/%3E%3C/svg%3E');
}
.wiki-blog .oo-ui-image-invert.oo-ui-indicator-required {
  background-image: url(https://wikipedia.org/w/load.php?modules=oojs-ui.styles.indicators&image=required&variant=invert&format=rasterized&skin=vector&version=gec2d);
  background-image: linear-gradient(transparent, transparent),
    url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Erequired%3C/title%3E%3Cg fill=%22%23fff%22%3E%3Cpath d=%22M8.5 0h3v20h-3z%22/%3E%3Cpath d=%22M19.41 13.701l-1.5 2.598L.59 6.3l1.5-2.598z%22/%3E%3Cpath d=%22M17.91 3.701l1.5 2.598-17.32 10-1.5-2.598z%22/%3E%3C/g%3E%3C/svg%3E');
}
.wiki-blog body {
  margin: 0;
}
.wiki-blog main {
  display: block;
}
.wiki-blog hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.wiki-blog abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
@supports (text-decoration: underline dotted) {
  .wiki-blog abbr[title] {
    border-bottom: 0;
    text-decoration: underline dotted;
  }
}
.wiki-blog pre,
.wiki-blog code,
.wiki-blog tt,
.wiki-blog kbd,
.wiki-blog samp {
  font-family: monospace, monospace;
}
.wiki-blog sub,
.wiki-blog sup {
  line-height: 1;
}
.wiki-blog button,
.wiki-blog input,
.wiki-blog optgroup,
.wiki-blog select,
.wiki-blog textarea {
  margin: 0;
}
.wiki-blog button::-moz-focus-inner,
.wiki-blog [type='button']::-moz-focus-inner,
.wiki-blog [type='reset']::-moz-focus-inner,
.wiki-blog [type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.wiki-blog legend {
  color: inherit;
  padding: 0;
}
.wiki-blog .firstHeading:before {
  content: url(/static/images/mobile/copyright/wikipedia-wordmark-en.svg);
  display: block;
  height: 18px;
  left: -9999px;
  line-height: 0;
  margin-bottom: 20px;
  position: absolute;
  width: 119px;
}
.wiki-blog .messagebox,
.wiki-blog .errorbox,
.wiki-blog .warningbox,
.wiki-blog .successbox {
  color: #181818;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 1px solid;
  padding: 12px 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.wiki-blog .messagebox :only-child,
.wiki-blog .errorbox :only-child,
.wiki-blog .warningbox :only-child,
.wiki-blog .successbox :only-child {
  margin: 0;
}
.wiki-blog .messagebox h2,
.wiki-blog .errorbox h2,
.wiki-blog .warningbox h2,
.wiki-blog .successbox h2 {
  color: inherit;
  display: inline;
  margin: 0 0.5em 0 0;
  border: 0;
  font-size: 1em;
  font-weight: bold;
}
.wiki-blog .messagebox .mw-logevent-loglines li,
.wiki-blog .errorbox .mw-logevent-loglines li,
.wiki-blog .warningbox .mw-logevent-loglines li,
.wiki-blog .successbox .mw-logevent-loglines li {
  font-size: 90%;
}
.wiki-blog .messagebox {
  background-color: #eaecf0;
  border-color: #a2a9b1;
}
.wiki-blog .errorbox {
  background-color: #fee7e6;
  border-color: #d33;
}
.wiki-blog .warningbox {
  background-color: #fef6e7;
  border-color: #fc3;
}
.wiki-blog .successbox {
  background-color: #d5fdf4;
  border-color: #14866d;
}
.wiki-blog .toctogglecheckbox:checked ~ ul {
  display: none;
}
@media screen {
  .wiki-blog html,
  .wiki-blog body {
    height: 100%;
  }

  .wiki-blog ul {
    list-style-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
  }
  .wiki-blog pre,
  .wiki-blog .mw-code {
    line-height: 1.3;
  }
  .wiki-blog .mw-jump-link:not(:focus) {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki-blog .mw-editsection,
  .wiki-blog .mw-editsection-like {
    font-family: sans-serif;
  }
  .wiki-blog .mw-body h1,
  .wiki-blog .mw-body-content h1,
  .wiki-blog .mw-body-content h2 {
    margin-bottom: 0.25em;
    padding: 0;
    font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
    line-height: 1.3;
  }
  .wiki-blog .mw-body h1:lang(ja),
  .wiki-blog .mw-body-content h1:lang(ja),
  .wiki-blog .mw-body-content h2:lang(ja),
  .wiki-blog .mw-body h1:lang(he),
  .wiki-blog .mw-body-content h1:lang(he),
  .wiki-blog .mw-body-content h2:lang(he),
  .wiki-blog .mw-body h1:lang(ko),
  .wiki-blog .mw-body-content h1:lang(ko),
  .wiki-blog .mw-body-content h2:lang(ko) {
    font-family: sans-serif;
  }
  .wiki-blog .mw-body h1:lang(my),
  .wiki-blog .mw-body-content h1:lang(my),
  .wiki-blog .mw-body-content h2:lang(my) {
    line-height: normal;
  }
  .wiki-blog .mw-body h1,
  .wiki-blog .mw-body-content h1 {
    font-size: 1.8em;
  }
  .wiki-blog .vector-body {
    font-size: 0.875em;
    font-size: calc(1em * 0.875);
    line-height: 1.6;
  }
  .wiki-blog .vector-body h1 {
    margin-top: 1em;
  }
  .wiki-blog .vector-body h2 {
    margin-top: 1em;
    font-size: 1.5em;
  }
  .wiki-blog .vector-body h3,
  .wiki-blog .vector-body h4,
  .wiki-blog .vector-body h5,
  .wiki-blog .vector-body h6 {
    margin-top: 0.3em;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.6;
  }
  .wiki-blog .vector-body h3 {
    font-size: 1.2em;
  }
  .wiki-blog .vector-body h3,
  .wiki-blog .vector-body h4 {
    font-weight: bold;
  }
  .wiki-blog .vector-body h4,
  .wiki-blog .vector-body h5,
  .wiki-blog .vector-body h6 {
    font-size: 100%;
  }
  .wiki-blog .vector-body .toc h2 {
    font-family: sans-serif;
    font-size: 100%;
  }
  .wiki-blog .vector-body p {
    margin: 0.5em 0;
  }
  .wiki-blog .vector-body blockquote {
    border-left: 4px solid #eaecf0;
    padding: 8px 32px;
  }
  .wiki-blog .vector-body blockquote > :first-child {
    margin-top: 0;
  }
  .wiki-blog .vector-body blockquote > :last-child {
    margin-bottom: 0;
  }
  .wiki-blog .mw-parser-output a.external {
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/external-link-ltr-icon.svg?48e54);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-body .mw-indicators {
    font-size: 0.875em;
    line-height: 1.6;
    position: relative;
  }
  .wiki-blog .mw-body .mw-indicator {
    display: inline-block;
  }
  .wiki-blog #siteNotice {
    font-size: 0.8em;
  }
  .wiki-blog .mw-portlet h3 {
    display: none;
  }
  .wiki-blog .mw-portlet ul {
    list-style: none none;
    margin: 0;
  }
  .wiki-blog .vector-menu-tabs {
    background-position: left bottom;
    float: left;
    height: 2.5em;
    padding-left: 1px;
  }
  .wiki-blog .vector-menu-tabs h3 {
    display: none;
  }
  .wiki-blog .vector-menu-tabs ul {
    float: left;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
  }
  .wiki-blog .vector-menu-tabs li {
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/tab-normal-fade.png?1cc52);
    background-image: linear-gradient(to top, #77c1f6 0, #e8f2f8 1px, #fff 100%);
    background-position: left bottom;
    background-repeat: repeat-x;
    float: left;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.125em;
    white-space: nowrap;
  }
  .wiki-blog .vector-menu-tabs li a {
    background-position: right bottom;
    color: #ee8ebe;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    float: left;
    height: 3.07692308em;
    position: relative;
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.8125em;
    cursor: pointer;
  }
  .wiki-blog .vector-menu-tabs .new a,
  .wiki-blog .vector-menu-tabs .new a:visited {
    color: #d33;
  }
  .wiki-blog .vector-menu-tabs .selected {
    background: #fff;
  }
  .wiki-blog .vector-menu-tabs .selected a,
  .wiki-blog .vector-menu-tabs .selected a:visited {
    color: #202122;
    text-decoration: none;
  }
  .wiki-blog .vector-menu-tabs .icon a {
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .wiki-blog .vector-menu-tabs .mw-watchlink.icon a {
    width: 2.15384615em;
    height: 0;
    padding: 3.07692308em 0 0 0;
    overflow: hidden;
  }
  .wiki-blog .vector-menu-tabs .mw-watchlink.icon a:before {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    content: '';
    display: block;
    position: absolute;
    top: 1.07692308em;
    left: 0.38461538em;
    width: 1.23076923em;
    height: 1.23076923em;
  }
  .wiki-blog .vector-menu-tabs #ca-unwatch.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/unwatch-icon.svg?c5368);
  }
  .wiki-blog .vector-menu-tabs #ca-unwatch.mw-watchlink-temp.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/unwatch-temp-icon.svg?195f5);
  }
  .wiki-blog .vector-menu-tabs #ca-watch.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/watch-icon.svg?6ab6f);
  }
  .wiki-blog .vector-menu-tabs #ca-unwatch.icon a:hover:before,
  .wiki-blog .vector-menu-tabs #ca-unwatch.icon a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/unwatch-icon-hl.svg?5e652);
  }
  .wiki-blog .vector-menu-tabs #ca-unwatch.mw-watchlink-temp.icon a:hover:before,
  .wiki-blog .vector-menu-tabs #ca-unwatch.mw-watchlink-temp.icon a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/unwatch-temp-icon-hl.svg?f3db6);
  }
  .wiki-blog .vector-menu-tabs #ca-watch.icon a:hover:before,
  .wiki-blog .vector-menu-tabs #ca-watch.icon a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(https://wikipedia.org/w/skins/Vector/resources/common/images/watch-icon-hl.svg?9bf90);
  }
  .wiki-blog .vector-menu-tabs #ca-unwatch.icon .loading:before,
  .wiki-blog .vector-menu-tabs #ca-watch.icon .loading:before {
    -webkit-animation: rotate 700ms infinite linear;
    animation: rotate 700ms infinite linear;
    outline: 0;
    cursor: default;
    pointer-events: none;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .wiki-blog .vector-menu-dropdown {
    direction: ltr;
    float: left;
    cursor: pointer;
    position: relative;
    line-height: 1.125em;
  }
  .wiki-blog .vector-menu-dropdown h3 {
    color: #54595d;
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .wiki-blog .vector-menu-dropdown h3:after {
    content: '';
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0.76923077em;
    right: 8px;
    bottom: 0;
    width: 1.23076923em;
    opacity: 0.84;
  }
  .wiki-blog .vector-menu-dropdown h3:hover,
  .wiki-blog .vector-menu-dropdown h3:focus {
    color: #202122;
  }
  .wiki-blog .vector-menu-dropdown h3:hover:after,
  .wiki-blog .vector-menu-dropdown h3:focus:after {
    opacity: 1;
  }
  .wiki-blog .vector-menu-dropdown .vector-menu-content {
    background-color: #fff;
    list-style: none none;
    min-width: 100%;
    position: absolute;
    top: 2.5em;
    left: -1px;
    margin: 0;
    border: 1px solid #a2a9b1;
    border-top-width: 0;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 100ms;
    transition: opacity 100ms;
    z-index: 3;
  }
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox-expanded,
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox-collapsed {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox:checked + .vector-menu-heading .vector-menu-checkbox-expanded,
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox:not(:checked) + .vector-menu-heading .vector-menu-checkbox-collapsed {
    display: inline !important;
  }
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox:not(:checked) + .vector-menu-heading .vector-menu-checkbox-expanded,
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox:checked + .vector-menu-heading .vector-menu-checkbox-collapsed {
    display: none !important;
  }
  .wiki-blog .vector-menu-dropdown .vector-menu-checkbox:checked ~ .vector-menu-content {
    opacity: 1;
    visibility: visible;
  }
  .wiki-blog .vector-menu-dropdown ul {
    list-style: none;
  }
  .wiki-blog .vector-menu-dropdown li {
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 1em;
  }
  .wiki-blog .vector-menu-dropdown li a {
    padding: 0.625em;
    color: #ee8ebe;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
  .wiki-blog .vector-menu-dropdown li a span {
    font-size: 0.8125em;
  }
  .wiki-blog .vector-menu-dropdown .selected a,
  .wiki-blog .vector-menu-dropdown .selected a:visited {
    color: #202122;
    text-decoration: none;
  }
  .wiki-blog #mw-head .vector-menu-dropdown h3 {
    background-position: right bottom;
    float: left;
    height: 3.07692308em;
    margin: 0 -1px 0 0;
    padding-right: 1.84615385em;
  }
  .wiki-blog .vector-menu-tabs,
  .wiki-blog .vector-menu-tabs a,
  .wiki-blog #mw-head .vector-menu-dropdown h3 {
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/tab-separator.png?09d4b);
    background-image: linear-gradient(to bottom, rgba(167, 215, 249, 0) 0, #a7d7f9 100%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
  }
  .wiki-blog .vector-menu-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
  }
  .wiki-blog :not(:checked) > .vector-menu-checkbox {
    display: block;
  }
  .wiki-blog .vector-menu-dropdown-noicon h3 {
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 1.84615385em;
    font-size: 0.8125em;
    font-weight: normal;
  }
  .wiki-blog .vector-menu-dropdown-noicon .vector-menu-checkbox:focus + h3 {
    outline: dotted 1px;
    outline: auto -webkit-focus-ring-color;
  }
  .wiki-blog .vector-menu-portal {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
  }
  .wiki-blog .vector-menu-portal h3 {
    display: block;
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/common/images/portal-separator.png?4ab04);
    background-image: linear-gradient(to right, rgba(200, 204, 209, 0) 0, #c8ccd1 33%, #c8ccd1 66%, rgba(200, 204, 209, 0) 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: #54595d;
    margin: 0.5em 0 0 0.66666667em;
    border: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    font-weight: normal;
    cursor: default;
  }
  .wiki-blog .vector-menu-portal .vector-menu-content {
    margin-left: 0.5em;
    padding-top: 0;
  }
  .wiki-blog .vector-menu-portal .vector-menu-content ul {
    list-style: none none;
    margin: 0;
    padding-top: 0.3em;
  }
  .wiki-blog .vector-menu-portal .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
  }
  .wiki-blog .vector-menu-portal .vector-menu-content li a {
    color: #ee8ebe;
  }
  .wiki-blog .vector-menu-portal .vector-menu-content li a:visited {
    color: #ee8ebe;
  }
  .wiki-blog .vector-search-box-inner {
    position: relative;
    height: 100%;
  }
  .wiki-blog .vector-search-box-input {
    background-color: rgba(255, 255, 255, 0.5);
    color: #181818;
    width: 100%;
    height: 2.15384615em;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #a2a9b1;
    border-radius: 2px;
    padding: 5px 2.15384615em 5px 0.4em;
    box-shadow: inset 0 0 0 1px transparent;
    font-family: inherit;
    font-size: 0.8125em;
    direction: ltr;
    -webkit-transition: border-color 250ms, box-shadow 250ms;
    transition: border-color 250ms, box-shadow 250ms;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  .wiki-blog .vector-search-box-inner:hover .vector-search-box-input {
    border-color: #72777d;
  }
  .wiki-blog .vector-search-box-input:focus,
  .wiki-blog .vector-search-box-inner:hover .vector-search-box-input:focus {
    outline: 0;
    border-color: #ee8ebe;
    box-shadow: inset 0 0 0 1px #ee8ebe;
  }
  .wiki-blog .vector-search-box-input::-webkit-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki-blog .vector-search-box-input:-ms-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki-blog .vector-search-box-input::-moz-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki-blog .vector-search-box-input::placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki-blog .vector-search-box-input::-webkit-search-decoration,
  .wiki-blog .vector-search-box-input::-webkit-search-cancel-button,
  .wiki-blog .vector-search-box-input::-webkit-search-results-button,
  .wiki-blog .vector-search-box-input::-webkit-search-results-decoration {
    display: none;
  }
  .wiki-blog .searchButton {
    background-color: transparent;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    min-width: 28px;
    width: 2.15384615em;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.8125em;
    direction: ltr;
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }
  .wiki-blog .searchButton[name='go'] {
    background: no-repeat center / 1.23076923em url(https://wikipedia.org/w/skins/Vector/resources/common/images/search.svg?bbf78);
    opacity: 0.67;
  }
  .wiki-blog .search-toggle {
    display: block;
    float: right;
  }
  .wiki-blog .vector-search-box-collapses > div {
    display: none;
  }
  .wiki-blog #p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
  }
  .wiki-blog #p-logo a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 10em;
    height: 160px;
    text-decoration: none;
  }
  .wiki-blog .mw-footer {
    direction: ltr;
  }
  .wiki-blog .mw-footer ul {
    list-style: none none;
    margin: 0;
    padding: 0;
  }
  .wiki-blog .mw-footer li {
    color: #202122;
    margin: 0;
    padding: 0.5em 0;
    font-size: 0.75em;
  }
  .wiki-blog #footer-icons {
    float: right;
  }
  .wiki-blog #footer-icons li {
    float: left;
    margin-left: 0.5em;
    line-height: 2;
    text-align: right;
  }
  .wiki-blog #footer-info li {
    line-height: 1.4;
  }
  .wiki-blog #footer-places li {
    float: left;
    margin-right: 1em;
    line-height: 2;
  }
}
@media screen and (min-width: 1000px) {
  .wiki-blog .search-toggle {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .wiki-blog .vector-search-box-collapses > div {
    display: block;
  }
}
@media print {
  .wiki-blog .toc,
  .wiki-blog body {
    padding: 10px;
  }
  .wiki-blog .printfooter,
  .wiki-blog .mw-footer,
  .wiki-blog .thumb,
  .wiki-blog table,
  .wiki-blog ol,
  .wiki-blog dl,
  .wiki-blog ul,
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    font-family: sans-serif;
  }
  .wiki-blog .mw-body a:not(.image) {
    border-bottom: 1px solid #aaa;
  }
  .wiki-blog .firstHeading {
    font-size: 25pt;
    line-height: 28pt;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }
  .wiki-blog .firstHeading,
  .wiki-blog h2 {
    overflow: hidden;
    border-bottom: 2px solid #181818;
  }
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    margin: 30px 0 0;
  }
  .wiki-blog h2,
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    padding: 0;
    position: relative;
  }
  .wiki-blog h2 {
    font-size: 18pt;
    line-height: 24pt;
    margin-bottom: 0.25em;
  }
  .wiki-blog h3 {
    font-size: 14pt;
    line-height: 20pt;
  }
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    font-size: 12pt;
    line-height: 16pt;
  }
  .wiki-blog p {
    font-size: 12pt;
    line-height: 16pt;
    margin-top: 5px;
    text-align: justify;
  }
  .wiki-blog p:before {
    content: '';
    display: block;
    width: 120pt;
    overflow: hidden;
  }
  .wiki-blog blockquote {
    border-left: 2px solid #181818;
    padding-left: 20px;
  }
  .wiki-blog ol,
  .wiki-blog ul {
    margin: 10px 0 0 1.6em;
    padding: 0;
  }
  .wiki-blog ol li,
  .wiki-blog ul li {
    padding: 2px 0;
    font-size: 12pt;
  }
  .wiki-blog table ol li,
  .wiki-blog table ul li {
    font-size: inherit;
  }
  .wiki-blog .toc {
    page-break-before: avoid;
    page-break-after: avoid;
    background: none;
    border: 0;
    display: table;
  }
  .wiki-blog .toc a {
    border: 0;
    font-weight: normal;
  }
  .wiki-blog .toc > ul > li {
    margin-bottom: 4px;
    font-weight: bold;
  }
  .wiki-blog .toc ul {
    margin: 0;
    list-style: none;
  }
  .wiki-blog .toc ul ul {
    padding-left: 30px;
  }
  .wiki-blog .toc li.toclevel-1 > a {
    font-size: 12pt;
    font-weight: bold;
  }
  .wiki-blog #mw-navigation,
  .wiki-blog .noprint,
  .wiki-blog .mw-jump-link,
  .wiki-blog .mw-portlet-lang,
  .wiki-blog .toc .tocnumber {
    display: none;
  }
  .wiki-blog .printfooter {
    margin-top: 10px;
    border-top: 3px solid #181818;
    padding-top: 10px;
    font-size: 10pt;
    clear: both;
  }
  .wiki-blog .mw-footer {
    margin-top: 12px;
    border-top: 1px solid #eee;
    padding-top: 5px;
  }
  .wiki-blog #footer-info {
    margin: 0;
    padding: 0;
  }
  .wiki-blog #footer-info li {
    color: #999;
    list-style: none;
    display: block;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  .wiki-blog #footer-info li a {
    color: #999 !important;
  }
  .wiki-blog #footer-info-lastmod {
    color: #181818;
    font-size: 12pt;
    font-weight: bold;
  }
}
@media screen {
  .wiki-blog body {
    background-color: #f6f6f6;
    overflow-y: scroll;
  }
  .wiki-blog .mw-body,
  .wiki-blog .parsoid-body {
    background-color: #fff;
    color: #202122;
    direction: ltr;
    padding: 1em;
  }
  .wiki-blog #p-personal {
    position: absolute;
    top: 6px;
    right: 0.75em;
    z-index: 100;
  }
  .wiki-blog #p-personal ul {
    padding-left: 10em;
  }
  .wiki-blog #p-personal li {
    float: left;
    margin-left: 0.75em;
    padding-top: 0.5em;
    line-height: 1.16666667;
  }
  .wiki-blog .mw-body,
  .wiki-blog #mw-data-after-content {
    margin-left: 10em;
  }
  .wiki-blog .mw-body {
    margin-top: -1px;
    border: 1px solid #a7d7f9;
    border-right-width: 0;
  }
  .wiki-blog .mw-body .firstHeading {
    overflow: visible;
  }
  .wiki-blog .mw-indicators {
    z-index: 1;
  }
  .wiki-blog .vector-body {
    position: relative;
    z-index: 0;
  }
  .wiki-blog #mw-page-base {
    background-color: #fbfbfb;
    background-image: linear-gradient(to bottom, #fff 50%, #f6f6f6 100%);
    background-position: bottom left;
    height: 5em;
  }
  .wiki-blog #mw-head-base {
    margin-top: -5em;
    margin-left: 10em;
    height: 5em;
  }
  .wiki-blog #mw-navigation h2 {
    position: absolute;
    top: -9999px;
  }
  .wiki-blog #mw-head {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
  .wiki-blog #left-navigation {
    float: left;
    margin-left: 10em;
    margin-top: 2.5em;
    margin-bottom: -2.5em;
  }
  .wiki-blog #right-navigation {
    float: right;
    margin-top: 2.5em;
  }
  .wiki-blog .vector-search-box {
    float: left;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  .wiki-blog .vector-search-box form {
    margin: 0.5em 0 0;
  }
  .wiki-blog .vector-search-box-inner {
    min-width: 5em;
    width: 13.2em;
    width: 20vw;
    max-width: 20em;
  }
  .wiki-blog #mw-panel {
    position: absolute;
    top: 0;
    width: 10em;
    left: 0;
  }
  .wiki-blog #p-logo + .mw-portlet h3 {
    display: none;
  }
  .wiki-blog .mw-footer {
    margin-left: 10em;
    margin-top: 0;
    padding: 0.75em;
  }
  .wiki-blog .mw-content-ltr {
    direction: ltr;
  }
  .wiki-blog .mw-content-rtl {
    direction: rtl;
  }
  .wiki-blog .vector-menu-dropdown:not(.vector-user-menu):hover .vector-menu-content {
    opacity: 1;
    visibility: visible;
  }
  .wiki-blog .vector-menu-checkbox:checked + h3:after {
    transform: scaleY(-1);
  }
  .wiki-blog .vector-menu-dropdown li a {
    padding: 0.5078125em;
  }
  .wiki-blog .vector-search-box h3 {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki-blog #mw-panel {
    font-size: inherit;
  }
  .wiki-blog #mw-panel nav:first-child {
    background-image: none;
  }
  .wiki-blog #mw-panel nav:first-child h3 {
    display: none;
  }
  .wiki-blog #mw-panel nav:first-child .vector-menu-content {
    margin-left: 0.5em;
  }
  .wiki-blog .vector-user-menu-legacy li {
    font-size: 0.75em;
  }
  .wiki-blog .vector-user-menu-legacy #pt-anonuserpage,
  .wiki-blog .vector-user-menu-legacy #pt-userpage a {
    background-position: left 0.33333333em;
    background-image: url(https://wikipedia.org/w/skins/Vector/resources/skins.vector.styles.legacy/images/user-avatar.svg?b7f58);
    background-repeat: no-repeat;
    background-size: 1.16666667em 1.16666667em;
    padding-top: 0.5em;
    padding-left: 1.5em;
  }
  .wiki-blog .vector-user-menu-legacy #pt-userpage {
    padding-top: 0;
  }
  .wiki-blog .vector-user-menu-legacy #pt-userpage a {
    display: inline-block;
  }
  .wiki-blog .vector-user-menu-legacy #pt-anonuserpage {
    color: #54595d;
  }
  .wiki-blog .vector-user-menu-legacy .vector-menu-content-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: baseline;
  }
  .wiki-blog .skin-vector-legacy a.new {
    color: #ba0000;
  }
  .wiki-blog .skin-vector-legacy a.new:visited,
  .wiki-blog .skin-vector-legacy .vector-menu-tabs .new a,
  .wiki-blog .skin-vector-legacy .vector-menu-tabs .new a:visited {
    color: #a55858;
  }
}
@media screen and (min-width: 982px) {
  .wiki-blog .mw-body,
  .wiki-blog #mw-head-base,
  .wiki-blog #left-navigation,
  .wiki-blog #mw-data-after-content,
  .wiki-blog .mw-footer {
    margin-left: 11em;
  }
  .wiki-blog .mw-body {
    padding: 1.25em 1.5em 1.5em 1.5em;
  }
  .wiki-blog .mw-footer {
    padding: 1.25em;
  }
  .wiki-blog #mw-panel {
    padding-left: 0.5em;
  }
  .wiki-blog .vector-search-box {
    margin-right: 1em;
  }
  .wiki-blog #p-personal {
    right: 1em;
  }
}
@media print {
  .wiki-blog .noprint,
  .wiki-blog .mw-cite-backlink,
  .wiki-blog .mw-redirectedfrom,
  .wiki-blog .patrollink,
  .wiki-blog #column-one,
  .wiki-blog #footer-places,
  .wiki-blog #mw-navigation,
  .wiki-blog #f-poweredbyico,
  .wiki-blog #f-copyrightico,
  .wiki-blog li#about,
  .wiki-blog li#disclaimer,
  .wiki-blog li#mobileview,
  .wiki-blog li#privacy {
    display: none;
  }
  .wiki-blog #footer {
    background: #fff;
    color: #181818;
    margin-top: 1em;
    border-top: 1pt solid #aaa;
    padding-top: 5px;
    direction: ltr;
  }
}
.wiki-blog .mw-wiki-logo {
  background-image: url(/static/images/project-logos/dewiki.png);
}
@media print {
  .wiki-blog .firstHeading:before {
    left: auto;
    position: relative;
  }
  .wiki-blog table.floatright,
  .wiki-blog div.floatright,
  .wiki-blog div.tright {
    float: right;
    clear: right;
  }
  .wiki-blog table.floatleft,
  .wiki-blog div.floatleft,
  .wiki-blog div.tleft {
    float: left;
    clear: left;
  }
  .wiki-blog div.tleft {
    margin: 0.5em 1.4em 1.3em 0;
  }
  .wiki-blog div.tright {
    margin: 0.5em 0 1.3em 1.4em;
  }
  .wiki-blog table.floatright,
  .wiki-blog div.floatright {
    margin: 0 0 0.5em 0.5em;
    border: 0;
  }
  .wiki-blog table.floatleft,
  .wiki-blog div.floatleft {
    margin: 0 0.5em 0.5em 0;
    border: 0;
  }
  .wiki-blog div.thumb {
    background-color: transparent;
    width: auto;
  }
  .wiki-blog div.thumb a {
    border-bottom: 0;
  }
  .wiki-blog div.thumbinner {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    padding: 5px;
    font-size: 10pt;
    color: #666;
    text-align: center;
    overflow: hidden;
    min-width: 100px;
  }
  .wiki-blog .thumbcaption {
    text-align: left;
    line-height: 1.4em;
    padding: 3px;
  }
  .wiki-blog .thumbborder {
    border: 1pt solid #ddd;
  }
  .wiki-blog .thumb {
    page-break-inside: avoid;
  }
  .wiki-blog .magnify {
    display: none;
  }
  .wiki-blog .mw-parser-output a.external.text:after,
  .wiki-blog .mw-parser-output a.external.autonumber:after {
    content: ' (' attr(href) ')';
    word-break: break-all;
    word-wrap: break-word;
  }
  .wiki-blog .mw-parser-output a.external.text[href^='//']:after, .wiki-blog .mw-parser-output a.external.autonumber[href^='//']:after
  {
    content: ' (https:' attr(href) ')';
  }
  .wiki-blog .wikitable {
    background: #fff;
    margin: 1em 0;
    border: 1pt solid #aaa;
    border-collapse: collapse;
    font-size: 10pt;
    page-break-inside: avoid;
  }
  .wiki-blog .wikitable > caption {
    padding: 5px;
    font-size: 10pt;
  }
  .wiki-blog .wikitable > tr > th,
  .wiki-blog .wikitable > tr > td,
  .wiki-blog .wikitable > * > tr > th,
  .wiki-blog .wikitable > * > tr > td {
    background: #fff !important;
    color: #181818 !important;
    border: 1pt solid #aaa;
    padding: 0.4em 0.6em;
  }

  .wiki-blog .wikitable tr > td {
    color: #fff;
  }

  .wiki-blog .wikitable > tr > th,
  .wiki-blog .wikitable > * > tr > th {
    text-align: center;
  }
  .wiki-blog table.listing,
  .wiki-blog table.listing td {
    border: 1pt solid #181818;
    border-collapse: collapse;
  }
  .wiki-blog .mw-editsection,
  .wiki-blog .mw-editsection-like,
  .wiki-blog .mw-indicators,
  .wiki-blog #siteNotice,
  .wiki-blog .usermessage {
    display: none;
  }
  .wiki-blog .printfooter {
    padding: 1em 0;
  }
  .wiki-blog .catlinks ul {
    display: inline;
    padding: 0;
    list-style: none;
  }
  .wiki-blog .catlinks li {
    display: inline-block;
    line-height: 1.15;
    margin: 0.1em 0;
    border-left: 1pt solid #aaa;
    padding: 0 0.4em;
  }
  .wiki-blog .catlinks li:first-child {
    border-left: 0;
    padding-left: 0.2em;
  }
  .wiki-blog .mw-hidden-catlinks,
  .wiki-blog .catlinks {
    display: none;
  }
  .wiki-blog .center {
    text-align: center;
  }
  .wiki-blog a {
    background: none !important;
    padding: 0 !important;
  }
  .wiki-blog a,
  .wiki-blog a.external,
  .wiki-blog a.new,
  .wiki-blog a.stub {
    color: inherit !important;
    text-decoration: inherit !important;
  }
  .wiki-blog dt {
    font-weight: bold;
  }
  .wiki-blog h1,
  .wiki-blog h2,
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    font-weight: bold;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .wiki-blog p {
    margin: 1em 0;
    line-height: 1.2;
    orphans: 3;
    widows: 3;
  }
  .wiki-blog img,
  .wiki-blog figure {
    page-break-inside: avoid;
  }
  .wiki-blog img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .wiki-blog pre,
  .wiki-blog .mw-code {
    background: #fff;
    color: #181818;
    border: 1pt dashed #181818;
    padding: 1em;
    font-size: 8pt;
    white-space: pre-wrap;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  .wiki-blog ul {
    list-style-type: square;
  }
  .wiki-blog .toctogglecheckbox:checked + .toctitle {
    display: none;
  }
  .wiki-blog .toc {
    background-color: #f9f9f9;
    border: 1pt solid #aaa;
    padding: 5px;
    display: table;
  }
  .wiki-blog .tocnumber,
  .wiki-blog .toctext {
    display: table-cell;
  }
  .wiki-blog .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki-blog .mw-content-ltr .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki-blog .mw-content-rtl .tocnumber {
    padding-left: 0.5em;
    padding-right: 0;
  }
}
@media screen {
  .wiki-blog div.tright,
  .wiki-blog div.floatright,
  .wiki-blog table.floatright {
    clear: right;
    float: right;
  }
  .wiki-blog div.tleft,
  .wiki-blog div.floatleft,
  .wiki-blog table.floatleft {
    float: left;
    clear: left;
  }
  .wiki-blog .mw-content-ltr .thumbcaption {
    text-align: left;
  }
  .wiki-blog .mw-content-ltr .magnify {
    float: right;
  }
  .wiki-blog .mw-content-rtl .thumbcaption {
    text-align: right;
  }
  .wiki-blog .mw-content-rtl .magnify {
    float: left;
  }
  .wiki-blog div.floatright,
  .wiki-blog table.floatright {
    margin: 0 0 0.5em 0.5em;
  }
  .wiki-blog div.floatleft,
  .wiki-blog table.floatleft {
    margin: 0 0.5em 0.5em 0;
  }
  .wiki-blog div.thumb {
    margin-bottom: 0.5em;
    width: auto;
    background-color: transparent;
  }
  .wiki-blog div.thumbinner {
    border: 1px solid #c8ccd1;
    padding: 3px;
    font-size: 94%;
    text-align: center;
    overflow: hidden;
  }
  .wiki-blog .thumbcaption {
    border: 0;
    line-height: 1.4em;
    padding: 3px;
    font-size: 94%;
    text-align: left;
  }
  .wiki-blog .magnify {
    float: right;
    margin-left: 3px;
  }
  .wiki-blog .magnify a {
    display: block;
    text-indent: 15px;
    white-space: nowrap;
    overflow: hidden;
    width: 15px;
    height: 11px;
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .wiki-blog .thumbborder {
    border: 1px solid #eaecf0;
  }
  .wiki-blog .mw-content-ltr .magnify {
    margin-left: 3px;
    margin-right: 0;
  }
  .wiki-blog .mw-content-ltr .magnify a {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
  }
  .wiki-blog .mw-content-rtl .magnify {
    margin-left: 0;
    margin-right: 3px;
  }
  .wiki-blog .mw-content-rtl .magnify a {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-rtl.svg?38fd5);
  }
  .wiki-blog div.tright {
    margin: 0.5em 0 1.3em 1.4em;
  }
  .wiki-blog div.tleft {
    margin: 0.5em 1.4em 1.3em 0;
  }
  .wiki-blog a.new {
    color: #d33;
  }
  .wiki-blog a.mw-selflink {
    color: inherit;
    font-weight: bold;
    text-decoration: inherit;
  }
  .wiki-blog a.mw-selflink:hover {
    cursor: inherit;
    text-decoration: inherit;
  }
  .wiki-blog a.mw-selflink:active,
  .wiki-blog a.mw-selflink:visited {
    color: inherit;
  }
  .wiki-blog a.new:visited {
    color: #a55858;
  }
  .wiki-blog .mw-parser-output a.extiw,
  .wiki-blog .mw-parser-output a.external {
    color: #ee8ebe;
  }
  .wiki-blog .mw-parser-output a.extiw:visited,
  .wiki-blog .mw-parser-output a.external:visited {
    color: #636;
  }
  .wiki-blog .mw-parser-output a.extiw:active,
  .wiki-blog .mw-parser-output a.external:active {
    color: #b63;
  }
  .wiki-blog .mw-underline-always a {
    text-decoration: underline;
  }
  .wiki-blog .mw-underline-never a {
    text-decoration: none;
  }
  .wiki-blog .plainlinks a.external {
    background: none !important;
    padding: 0 !important;
  }
  .wiki-blog .mw-parser-output a.external,
  .wiki-blog .link-https {
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href^='mailto:'],
  .wiki-blog .link-mailto {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/mail.svg?8a79c);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href^='ftp://'], .wiki-blog .link-ftp
  {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/ftp-ltr.svg?c5643);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href^='irc://'], .wiki-blog .mw-parser-output a.external[href^='ircs://'], .wiki-blog .link-irc
  {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/chat-ltr.svg?e0649);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href$='.ogg'],
  .wiki-blog .mw-parser-output a.external[href$='.OGG'],
  .wiki-blog .mw-parser-output a.external[href$='.mid'],
  .wiki-blog .mw-parser-output a.external[href$='.MID'],
  .wiki-blog .mw-parser-output a.external[href$='.midi'],
  .wiki-blog .mw-parser-output a.external[href$='.MIDI'],
  .wiki-blog .mw-parser-output a.external[href$='.mp3'],
  .wiki-blog .mw-parser-output a.external[href$='.MP3'],
  .wiki-blog .mw-parser-output a.external[href$='.wav'],
  .wiki-blog .mw-parser-output a.external[href$='.WAV'],
  .wiki-blog .mw-parser-output a.external[href$='.wma'],
  .wiki-blog .mw-parser-output a.external[href$='.WMA'],
  .wiki-blog .link-audio {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/audio-ltr.svg?74d04);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href$='.ogm'],
  .wiki-blog .mw-parser-output a.external[href$='.OGM'],
  .wiki-blog .mw-parser-output a.external[href$='.avi'],
  .wiki-blog .mw-parser-output a.external[href$='.AVI'],
  .wiki-blog .mw-parser-output a.external[href$='.mpeg'],
  .wiki-blog .mw-parser-output a.external[href$='.MPEG'],
  .wiki-blog .mw-parser-output a.external[href$='.mpg'],
  .wiki-blog .mw-parser-output a.external[href$='.MPG'],
  .wiki-blog .link-video {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/video.svg?a3758);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.external[href$='.pdf'],
  .wiki-blog .mw-parser-output a.external[href$='.PDF'],
  .wiki-blog .mw-parser-output a.external[href*='.pdf#'],
  .wiki-blog .mw-parser-output a.external[href*='.PDF#'],
  .wiki-blog .mw-parser-output a.external[href*='.pdf?'],
  .wiki-blog .mw-parser-output a.external[href*='.PDF?'],
  .wiki-blog .link-document {
    background-image: url(https://wikipedia.org/w/resources/src/mediawiki.skinning/images/document-ltr.svg?0bf90);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki-blog .mw-parser-output a.extiw,
  .wiki-blog .mw-parser-output a.extiw:active {
    color: #ee8ebe;
  }
  .wiki-blog .mw-parser-output a.external {
    color: #ee8ebe;
  }
  .wiki-blog .mw-body-content:after {
    clear: both;
    content: '';
    display: block;
  }
  .wiki-blog .mw-body-content a.external.free {
    word-wrap: break-word;
  }
  .wiki-blog .mw-body-content .error {
    font-size: larger;
    color: #d33;
  }
  .wiki-blog .rtl .mw-parser-output a.external.free,
  .wiki-blog .rtl .mw-parser-output a.external.autonumber {
    direction: ltr;
    unicode-bidi: embed;
  }
  .wiki-blog .mw-hide-empty-elt .mw-parser-output .mw-empty-elt {
    display: none;
  }
  .wiki-blog .wikitable {
    color: #202122;
    margin: 1em 0;
    border: 1px solid #a2a9b1;
    border-collapse: collapse;
  }
  .wiki-blog .wikitable > tr > th,
  .wiki-blog .wikitable > tr > td,
  .wiki-blog .wikitable > * > tr > th,
  .wiki-blog .wikitable > * > tr > td {
    border: 1px solid #a2a9b1;
    padding: 0.2em 0.4em;
    color: #fff;
  }
  .wiki-blog .wikitable > tr > th,
  .wiki-blog .wikitable > * > tr > th {
    color: #181818;
    background-color: #eaecf0;
    text-align: center;
  }
  .wiki-blog .wikitable > caption {
    font-weight: bold;
  }
  .wiki-blog .usermessage {
    background-color: #fef6e7;
    border: 1px solid #fc3;
    color: #181818;
    font-weight: bold;
    margin: 2em 0 1em;
    padding: 0.5em 1em;
    vertical-align: middle;
  }
  .wiki-blog #siteNotice {
    position: relative;
    text-align: center;
    margin: 0;
  }
  .wiki-blog #localNotice {
    margin-bottom: 0.9em;
  }
  .wiki-blog #siteSub {
    display: none;
  }
  .wiki-blog #contentSub,
  .wiki-blog #contentSub2 {
    font-size: 84%;
    line-height: 1.2em;
    margin: 0 0 1.4em 1em;
    color: #54595d;
    width: auto;
  }
  .wiki-blog span.subpages {
    display: block;
  }
  .wiki-blog .emptyPortlet {
    display: none;
  }
  .wiki-blog .printfooter,
  .wiki-blog .client-nojs #t-print {
    display: none;
  }
  .wiki-blog .mw-indicators {
    float: right;
  }
  .wiki-blog .mw-editsection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .wiki-blog .mw-editsection,
  .wiki-blog .mw-editsection-like {
    display: none;
  }
  .wiki-blog .mw-content-ltr .mw-editsection,
  .wiki-blog .mw-content-rtl .mw-content-ltr .mw-editsection,
  .wiki-blog .mw-content-ltr .mw-editsection-like,
  .wiki-blog .mw-content-rtl .mw-content-ltr .mw-editsection-like {
    margin-left: 1em;
    margin-right: 0;
  }
  .wiki-blog .mw-content-rtl .mw-editsection,
  .wiki-blog .mw-content-ltr .mw-content-rtl .mw-editsection,
  .wiki-blog .mw-content-rtl .mw-editsection-like,
  .wiki-blog .mw-content-ltr .mw-content-rtl .mw-editsection-like {
    margin-right: 1em;
    margin-left: 0;
  }
  .wiki-blog #catlinks {
    text-align: left;
  }
  .wiki-blog .catlinks {
    border: 1px solid #a2a9b1;
    padding: 5px;
    margin-top: 1em;
    clear: both;
  }
  .wiki-blog .catlinks ul {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .wiki-blog .catlinks li {
    display: inline-block;
    line-height: 1.25em;
    border-left: 1px solid #a2a9b1;
    margin: 0.125em 0;
    padding: 0 0.5em;
  }
  .wiki-blog .catlinks li:first-child {
    padding-left: 0.25em;
    border-left: 0;
  }
  .wiki-blog .catlinks li a.mw-redirect {
    font-style: italic;
  }
  .wiki-blog .mw-hidden-cats-hidden,
  .wiki-blog .catlinks-allhidden {
    display: none;
  }
  .wiki-blog a {
    text-decoration: none;
    color: #ee8ebe;
    background: none;
  }
  .wiki-blog a:not([href]) {
    cursor: pointer;
  }
  .wiki-blog a:visited {
    color: #ee8ebe;
  }
  .wiki-blog a:active {
    color: #faa700;
  }
  .wiki-blog a:hover,
  .wiki-blog a:focus {
    text-decoration: underline;
  }
  .wiki-blog a:lang(ar),
  .wiki-blog a:lang(kk-arab),
  .wiki-blog a:lang(mzn),
  .wiki-blog a:lang(ps),
  .wiki-blog a:lang(ur) {
    text-decoration: none;
  }

  .wiki-blog hr {
    height: 1px;
    background-color: #a2a9b1;
    border: 0;
    margin: 0.2em 0;
  }
  .wiki-blog h1,
  .wiki-blog h2,
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5,
  .wiki-blog h6 {
    color: #181818;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.17em;
    overflow: hidden;
  }
  .wiki-blog h1,
  .wiki-blog h2 {
    margin-bottom: 0.6em;
    border-bottom: 1px solid #a2a9b1;
  }
  .wiki-blog h3,
  .wiki-blog h4,
  .wiki-blog h5 {
    margin-bottom: 0.3em;
  }
  .wiki-blog h1 {
    font-size: 188%;
    font-weight: normal;
  }
  .wiki-blog h2 {
    font-size: 150%;
    font-weight: normal;
  }
  .wiki-blog h3 {
    font-size: 128%;
  }
  .wiki-blog h4 {
    font-size: 116%;
  }
  .wiki-blog h5 {
    font-size: 108%;
  }
  .wiki-blog h6 {
    font-size: 100%;
  }
  .wiki-blog p {
    text-align: justify;
    margin: 0.4em 0 0.5em 0;
  }
  .wiki-blog p img {
    margin: 0;
  }
  .wiki-blog ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
  }
  .wiki-blog ol {
    margin: 0.3em 0 0 3.2em;
    padding: 0;
    list-style-image: none;
  }
  .wiki-blog li {
    margin-bottom: 0.1em;
  }
  .wiki-blog dt {
    font-weight: bold;
    margin-bottom: 0.1em;
  }
  .wiki-blog dl {
    margin-top: 0.2em;
    margin-bottom: 0.5em;
  }
  .wiki-blog dd {
    margin-left: 1.6em;
    margin-bottom: 0.1em;
  }
  .wiki-blog pre,
  .wiki-blog code,
  .wiki-blog tt,
  .wiki-blog kbd,
  .wiki-blog samp,
  .wiki-blog .mw-code {
    font-family: monospace, monospace;
  }
  .wiki-blog pre,
  .wiki-blog code,
  .wiki-blog .mw-code {
    color: #181818;
    border: 1px solid #eaecf0;
  }
  .wiki-blog code {
    border-radius: 2px;
    padding: 1px 4px;
    color: #508bd8;
  }
  .wiki-blog pre,
  .wiki-blog .mw-code {
    padding: 1em;
    white-space: pre-wrap;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  .wiki-blog table {
    font-size: 100%;
  }
  .wiki-blog fieldset {
    border: 1px solid #2a4b8d;
    margin: 1em 0 1em 0;
    padding: 0 1em 1em;
  }
  .wiki-blog legend {
    padding: 0.5em;
    font-size: 95%;
  }
  .wiki-blog form {
    border: 0;
    margin: 0;
  }
  .wiki-blog textarea {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #c8ccd1;
    padding: 0.1em;
  }
  .wiki-blog .center {
    width: 100%;
    text-align: center;
  }
  .wiki-blog *.center * {
    margin-left: auto;
    margin-right: auto;
  }
  .wiki-blog .small {
    font-size: 94%;
  }
  .wiki-blog table.small {
    font-size: 100%;
  }
  @counter-style meetei {
    system: numeric;
    symbols: '\ABF0''\ABF1''\ABF2''\ABF3''\ABF4''\ABF5''\ABF6''\ABF7''\ABF8''\ABF9';
    suffix: ') ';
  }
  @counter-style santali {
    system: numeric;
    symbols: '\1C50''\1C51''\1C52''\1C53''\1C54''\1C55''\1C56''\1C57''\1C58''\1C59';
  }
  .wiki-blog ol:lang(azb) li,
  .wiki-blog ol:lang(bcc) li,
  .wiki-blog ol:lang(bgn) li,
  .wiki-blog ol:lang(bqi) li,
  .wiki-blog ol:lang(fa) li,
  .wiki-blog ol:lang(glk) li,
  .wiki-blog ol:lang(kk-arab) li,
  .wiki-blog ol:lang(lrc) li,
  .wiki-blog ol:lang(luz) li,
  .wiki-blog ol:lang(mzn) li {
    list-style-type: persian;
  }
  .wiki-blog ol:lang(ckb) li,
  .wiki-blog ol:lang(sdh) li {
    list-style-type: arabic-indic;
  }
  .wiki-blog ol:lang(hi) li,
  .wiki-blog ol:lang(mai) li,
  .wiki-blog ol:lang(mr) li,
  .wiki-blog ol:lang(ne) li {
    list-style-type: devanagari;
  }
  .wiki-blog ol:lang(as) li,
  .wiki-blog ol:lang(bn) li {
    list-style-type: bengali;
  }
  .wiki-blog ol:lang(mni) li {
    list-style-type: meetei;
  }
  .wiki-blog ol:lang(or) li {
    list-style-type: oriya;
  }
  .wiki-blog ol:lang(sat) li {
    list-style-type: santali;
  }
  .wiki-blog .mw-content-ltr ul,
  .wiki-blog .mw-content-rtl .mw-content-ltr ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
  }
  .wiki-blog .mw-content-rtl ul,
  .wiki-blog .mw-content-ltr .mw-content-rtl ul {
    margin: 0.3em 1.6em 0 0;
    padding: 0;
  }
  .wiki-blog .mw-content-ltr ol,
  .wiki-blog .mw-content-rtl .mw-content-ltr ol {
    margin: 0.3em 0 0 3.2em;
    padding: 0;
  }
  .wiki-blog .mw-content-rtl ol,
  .wiki-blog .mw-content-ltr .mw-content-rtl ol {
    margin: 0.3em 3.2em 0 0;
    padding: 0;
  }
  .wiki-blog .mw-content-ltr dd,
  .wiki-blog .mw-content-rtl .mw-content-ltr dd {
    margin-left: 1.6em;
    margin-right: 0;
  }
  .wiki-blog .mw-content-rtl dd,
  .wiki-blog .mw-content-ltr .mw-content-rtl dd {
    margin-right: 1.6em;
    margin-left: 0;
  }
  .wiki-blog h1:lang(anp),
  .wiki-blog h1:lang(as),
  .wiki-blog h1:lang(bh),
  .wiki-blog h1:lang(bho),
  .wiki-blog h1:lang(bn),
  .wiki-blog h1:lang(gu),
  .wiki-blog h1:lang(hi),
  .wiki-blog h1:lang(kn),
  .wiki-blog h1:lang(ks),
  .wiki-blog h1:lang(ml),
  .wiki-blog h1:lang(mr),
  .wiki-blog h1:lang(my),
  .wiki-blog h1:lang(mai),
  .wiki-blog h1:lang(ne),
  .wiki-blog h1:lang(new),
  .wiki-blog h1:lang(or),
  .wiki-blog h1:lang(pa),
  .wiki-blog h1:lang(pi),
  .wiki-blog h1:lang(sa),
  .wiki-blog h1:lang(ta),
  .wiki-blog h1:lang(te) {
    line-height: 1.6em !important;
  }
  .wiki-blog h2:lang(anp),
  .wiki-blog h3:lang(anp),
  .wiki-blog h4:lang(anp),
  .wiki-blog h5:lang(anp),
  .wiki-blog h6:lang(anp),
  .wiki-blog h2:lang(as),
  .wiki-blog h3:lang(as),
  .wiki-blog h4:lang(as),
  .wiki-blog h5:lang(as),
  .wiki-blog h6:lang(as),
  .wiki-blog h2:lang(bho),
  .wiki-blog h3:lang(bho),
  .wiki-blog h4:lang(bho),
  .wiki-blog h5:lang(bho),
  .wiki-blog h6:lang(bho),
  .wiki-blog h2:lang(bh),
  .wiki-blog h3:lang(bh),
  .wiki-blog h4:lang(bh),
  .wiki-blog h5:lang(bh),
  .wiki-blog h6:lang(bh),
  .wiki-blog h2:lang(bn),
  .wiki-blog h3:lang(bn),
  .wiki-blog h4:lang(bn),
  .wiki-blog h5:lang(bn),
  .wiki-blog h6:lang(bn),
  .wiki-blog h2:lang(gu),
  .wiki-blog h3:lang(gu),
  .wiki-blog h4:lang(gu),
  .wiki-blog h5:lang(gu),
  .wiki-blog h6:lang(gu),
  .wiki-blog h2:lang(hi),
  .wiki-blog h3:lang(hi),
  .wiki-blog h4:lang(hi),
  .wiki-blog h5:lang(hi),
  .wiki-blog h6:lang(hi),
  .wiki-blog h2:lang(kn),
  .wiki-blog h3:lang(kn),
  .wiki-blog h4:lang(kn),
  .wiki-blog h5:lang(kn),
  .wiki-blog h6:lang(kn),
  .wiki-blog h2:lang(ks),
  .wiki-blog h3:lang(ks),
  .wiki-blog h4:lang(ks),
  .wiki-blog h5:lang(ks),
  .wiki-blog h6:lang(ks),
  .wiki-blog h2:lang(ml),
  .wiki-blog h3:lang(ml),
  .wiki-blog h4:lang(ml),
  .wiki-blog h5:lang(ml),
  .wiki-blog h6:lang(ml),
  .wiki-blog h2:lang(mr),
  .wiki-blog h3:lang(mr),
  .wiki-blog h4:lang(mr),
  .wiki-blog h5:lang(mr),
  .wiki-blog h6:lang(mr),
  .wiki-blog h2:lang(my),
  .wiki-blog h3:lang(my),
  .wiki-blog h4:lang(my),
  .wiki-blog h5:lang(my),
  .wiki-blog h6:lang(my),
  .wiki-blog h2:lang(mai),
  .wiki-blog h3:lang(mai),
  .wiki-blog h4:lang(mai),
  .wiki-blog h5:lang(mai),
  .wiki-blog h6:lang(mai),
  .wiki-blog h2:lang(ne),
  .wiki-blog h3:lang(ne),
  .wiki-blog h4:lang(ne),
  .wiki-blog h5:lang(ne),
  .wiki-blog h6:lang(ne),
  .wiki-blog h2:lang(new),
  .wiki-blog h3:lang(new),
  .wiki-blog h4:lang(new),
  .wiki-blog h5:lang(new),
  .wiki-blog h6:lang(new),
  .wiki-blog h2:lang(or),
  .wiki-blog h3:lang(or),
  .wiki-blog h4:lang(or),
  .wiki-blog h5:lang(or),
  .wiki-blog h6:lang(or),
  .wiki-blog h2:lang(pa),
  .wiki-blog h3:lang(pa),
  .wiki-blog h4:lang(pa),
  .wiki-blog h5:lang(pa),
  .wiki-blog h6:lang(pa),
  .wiki-blog h2:lang(pi),
  .wiki-blog h3:lang(pi),
  .wiki-blog h4:lang(pi),
  .wiki-blog h5:lang(pi),
  .wiki-blog h6:lang(pi),
  .wiki-blog h2:lang(sa),
  .wiki-blog h3:lang(sa),
  .wiki-blog h4:lang(sa),
  .wiki-blog h5:lang(sa),
  .wiki-blog h6:lang(sa),
  .wiki-blog h2:lang(ta),
  .wiki-blog h3:lang(ta),
  .wiki-blog h4:lang(ta),
  .wiki-blog h5:lang(ta),
  .wiki-blog h6:lang(ta),
  .wiki-blog h2:lang(te),
  .wiki-blog h3:lang(te),
  .wiki-blog h4:lang(te),
  .wiki-blog h5:lang(te),
  .wiki-blog h6:lang(te) {
    line-height: 1.4em;
  }
  .wiki-blog h2:lang(th) {
    line-height: 1.6;
  }
  .wiki-blog .toc,
  .wiki-blog .toccolours {
    border: 1px solid #a2a9b1;
    padding: 5px;
    font-size: 95%;
  }
  .wiki-blog .toc {
    display: table;
    padding: 7px;
  }
  .wiki-blog .toc h2 {
    display: inline;
    border: 0;
    padding: 0;
    font-size: 100%;
    font-weight: bold;
  }
  .wiki-blog .toc .toctitle {
    text-align: center;
  }
  .wiki-blog .toc ul {
    list-style: none;
    margin: 0.3em 0;
    padding: 0;
    text-align: left;
  }
  .wiki-blog .toc ul ul {
    margin: 0 0 0 2em;
  }
  .wiki-blog table.toc {
    border-collapse: collapse;
  }
  .wiki-blog table.toc td {
    padding: 0;
  }
  .wiki-blog .tocnumber,
  .wiki-blog .toctext {
    display: table-cell;
    text-decoration: inherit;
  }
  .wiki-blog .tocnumber {
    color: #fff;
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki-blog .mw-content-ltr .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki-blog .mw-content-rtl .tocnumber {
    padding-left: 0.5em;
    padding-right: 0;
  }
  .wiki-blog .toctogglecheckbox {
    display: inline !important;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .wiki-blog .toctogglespan {
    font-size: 94%;
  }
  .wiki-blog .toctogglespan:before {
    content: ' [';
  }
  .wiki-blog .toctogglespan:after {
    content: ']';
  }
  .wiki-blog .toctogglelabel {
    cursor: pointer;
    color: #ee8ebe;
  }
  .wiki-blog .toctogglelabel:hover {
    text-decoration: underline;
  }
  .wiki-blog .toctogglecheckbox:focus + .toctitle .toctogglelabel {
    text-decoration: underline;
    outline: dotted 1px;
    outline: auto -webkit-focus-ring-color;
  }
  .wiki-blog .toctogglecheckbox:checked + .toctitle .toctogglelabel:after {
    content: 'Anzeigen';
  }
  .wiki-blog .toctogglecheckbox:not(:checked) + .toctitle .toctogglelabel:after {
    content: 'Verbergen';
  }
  .wiki-blog .toc .toctitle {
    direction: ltr;
  }
  .wiki-blog .mw-content-ltr .toc ul,
  .wiki-blog .mw-content-rtl .mw-content-ltr .toc ul {
    text-align: left;
  }
  .wiki-blog .mw-content-rtl .toc ul,
  .wiki-blog .mw-content-ltr .mw-content-rtl .toc ul {
    text-align: right;
  }
  .wiki-blog .mw-content-ltr .toc ul ul,
  .wiki-blog .mw-content-rtl .mw-content-ltr .toc ul ul {
    margin: 0 0 0 2em;
  }
  .wiki-blog .mw-content-rtl .toc ul ul,
  .wiki-blog .mw-content-ltr .mw-content-rtl .toc ul ul {
    margin: 0 2em 0 0;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx), (min-resolution: 144dpi) {
  .wiki-blog .mw-wiki-logo {
    background-image: url(/static/images/project-logos/dewiki-1.5x.png);
    background-size: 135px auto;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi) {
  .wiki-blog .mw-wiki-logo {
    background-image: url(/static/images/project-logos/dewiki-2x.png);
    background-size: 135px auto;
  }
}
.wiki-blog .wb-langlinks-link {
  line-height: 1.125em;
  font-size: 0.75em;
  float: right;
}
.wiki-blog .wb-langlinks-link {
  list-style: none;
  text-align: right;
  padding-right: 0.5em !important;
}
.wiki-blog .wb-langlinks-link > a:before {
  content: '';
  background-image: url(https://wikipedia.org/w/extensions/Wikibase/client/resources/images/edit.svg?34bc5);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 2px;
  vertical-align: top;
}
.wiki-blog .wb-langlinks-link > a:link,
.wiki-blog .wb-langlinks-link > a:visited {
  color: #54595d !important;
}
.wiki-blog .wb-langlinks-link > a:link:before,
.wiki-blog .wb-langlinks-link > a:visited:before {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.73;
}
.wiki-blog .wb-langlinks-link > a:hover {
  color: #ee8ebe !important;
}
.wiki-blog .wb-langlinks-link > a:hover:before {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}
.wiki-blog div.after-portlet-lang:after {
  content: '';
  clear: both;
  display: block;
}
.wiki-blog .skin-vector:not(.skin-vector-legacy) .wb-langlinks-link {
  text-align: left;
  float: none;
}
.wiki-blog .skin-vector:not(.skin-vector-legacy) .wb-langlinks-link a {
  color: #ee8ebe !important;
}
table.infobox {
  padding: 5px;
  margin: 0 0 1em 1em;
  width: 25em;
  font-size: 85%;
  float: right;
}
.thumbcaption {
  color: black;
}
.toc a {
  color: #ee8ebe;
}
.dark .wiki-blog h1,
.dark .wiki-blog h2,
.dark .wiki-blog h3,
.dark .wiki-blog h4,
.dark .wiki-blog h5,
.dark .wiki-blog h6 {
  color: white;
}
.dark .wiki-blog h2 {
  border-bottom: solid 1px white;
}
.light .wiki-blog h2 {
  border-bottom: solid 1px black;
}
.hatnote {
  display: none;
}

table.infobox {
  width: 22em !important;
}

@media (max-width: 720px) {
  table.infobox {
    width: 100% !important;
  }
}

table.infobox img {
  margin: 0 auto;
}

.infobox.vcard.plainlist .infobox-above {
  background-color: #181818 !important;
}

.mw-parser-output .sidebar {
  background: #181818 !important;
  border: 1px solid #ee8ebe !important;
}

.mw-parser-output .sister-box {
  background: #181818 !important;
  border: 1px solid #ee8ebe !important;
}

.mw-parser-output .navbox {
  background: #181818 !important;
  border: 1px solid #ee8ebe !important;
}

.wiki-blog code,
.wiki-blog .mw-code {
  background-color: rgba(255, 255, 255, 0.1);
}

.hlist dl,
.hlist ol,
.hlist ul {
  margin: 0;
  padding: 0;
}

.hlist dd,
.hlist dt,
.hlist li {
  margin: 0;
  display: inline;
}

.hlist.inline,
.hlist.inline dl,
.hlist.inline ol,
.hlist.inline ul,
.hlist dl dl,
.hlist dl ol,
.hlist dl ul,
.hlist ol dl,
.hlist ol ol,
.hlist ol ul,
.hlist ul dl,
.hlist ul ol,
.hlist ul ul {
  display: inline;
}

.hlist dd dd:first-child:before,
.hlist dd dt:first-child:before,
.hlist dd li:first-child:before,
.hlist dt dd:first-child:before,
.hlist dt dt:first-child:before,
.hlist dt li:first-child:before,
.hlist li dd:first-child:before,
.hlist li dt:first-child:before,
.hlist li li:first-child:before {
  content: ' (';
  font-weight: normal;
}

.hlist dd dd:last-child:after,
.hlist dd dt:last-child:after,
.hlist dd li:last-child:after,
.hlist dt dd:last-child:after,
.hlist dt dt:last-child:after,
.hlist dt li:last-child:after,
.hlist li dd:last-child:after,
.hlist li dt:last-child:after,
.hlist li li:last-child:after {
  content: ')';
  font-weight: normal;
}

.hlist dd:after,
.hlist li:after {
  content: ' · ';
  font-weight: bold;
}

.nowrap,
.nowraplinks a {
  white-space: nowrap;
}

.mw-content-ltr .tocnumber {
  padding-left: 0;
  padding-right: 0.5em;
}

.toc ul {
  list-style: none;
  margin: 0.3em 0;
  padding: 0;
  text-align: left;
}

.mw-content-ltr .toc ul,
.mw-content-rtl .mw-content-ltr .toc ul {
  text-align: left;
}

.tocnumber,
.toctext {
  display: table-cell;
  text-decoration: inherit;
}

.mw-parser-output .navbox-abovebelow,
.mw-parser-output th.navbox-group,
.mw-parser-output .navbox-subgroup .navbox-title {
  background-color: #181818 !important;
  color: #fff !important;
}

.wiki-blog .mw-parser-output .navbox-even {
  background-color: #181818;
}

.wiki-blog .mw-parser-output .navbox-odd {
  background-color: #141414;
}

@media (max-width: 720px) {
  .mw-parser-output .sidebar {
    width: 100% !important;
    clear: both;
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wiki-blog ul,
.wiki-blog ul li {
  list-style-type: none;
}

.wiki-blog ul li:before {
  content: '\2022';
  width: 1em;
  margin-left: -1em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
}

.wiki-blog .mbox-small {
  clear: right;
  float: right;
  margin: 4px 0 4px 1em;
  box-sizing: border-box;
  width: 238px;
  font-size: 88%;
  line-height: 1.25em;
}

.wiki-blog .plainlist ol,
.wiki-blog .plainlist ul {
  line-height: inherit;
  list-style: none none;
  margin: 0;
}

.wiki-blog .plainlist ul li::before {
  content: '';
}

.wiki-blog .toc ul li::before {
  content: '';
}

.wiki-blog .navbox-list ul li::before {
  content: '';
}

.wiki-blog .mw-parser-output .portal-bar-bordered {
  background-color: #181818;
  border-color: #fff;
}

.wiki-blog .mw-parser-output .portal-bar {
  font-weight: bold;
  font-size: 88%;
  text-align: center;
}

.wiki-blog .mw-parser-output .portal-bar > li {
  display: inline-flex;
  white-space: nowrap;
}

.wiki-blog .mw-parser-output .portal-bar-bordered li::before {
  content: '';
}
