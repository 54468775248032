.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label::before {
  margin-left: -10px;
  font-size: 1.25em;
  display: inline-block;
  width: 40px;
  height: 40px;
  content: '';
  -webkit-mask: url('/assets/star.svg');
  mask: url('/assets/star.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  background: white;
}

.rating > label {
  color: #ddd;
  float: right;
}

.rating > input:checked ~ label:before,
.rating:not(:checked) > label:hover:before,
.rating:not(:checked) > label:hover ~ label:before {
  background: #de72b2;
}

.rating > input:checked + label:hover::before,
.rating > input:checked ~ label:hover::before,
.rating > label:hover ~ input:checked ~ label:before,
.rating > input:checked ~ label:hover ~ label:before {
  background: #4890df;
}
